import React, {useContext, useState} from 'react';
import {Form} from "react-bootstrap";
import API from "../../../../Request/API";
import {EvidencePageContext} from "../../../services/EvidencePageService";

type EvidenceStateTableDataProps = {
    evidenceId: number,
    stateList: string[],
    predictedState: string,
}

const Statepicker: React.FC<EvidenceStateTableDataProps> = ({evidenceId, stateList, predictedState}) => {
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const {updateEvidenceState} = useContext(EvidencePageContext);

    const handleOnChange = (event: { target: { value: string; }; }) => {
        const selectedState = event.target.value;
        setShowSuccessMessage(false);

        API.post('/api/evidence/update', {"evidence_id": evidenceId, "predicted_state": selectedState}, {withCredentials: true})
                .then((resp) => {
                    if (resp.status === 200) {
                        setShowSuccessMessage(true)
                        updateEvidenceState([evidenceId], selectedState);
                    }
                })
                .catch(error => {
                    console.log(error.toJSON());
                })
    }

    return (
            <td>
                <Form.Group className="p-1">
                    <Form.Select id={"type"} onChange={handleOnChange} defaultValue={predictedState}>
                        {stateList.map((state) => {
                                    return <option key={state} value={state}>{state}</option>
                                }
                        )}
                    </Form.Select>
                </Form.Group>
                {showSuccessMessage && <p style={{color: 'green'}}>New State saved.</p>}
            </td>
    );
}

export default Statepicker;
