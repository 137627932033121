import React from "react";
import {Route, Routes} from "react-router-dom";
import {PrivateRoute} from "./Auth/services/PrivateRoute";
import LoginPage from "./Auth/pages/LoginPage";
import LandingPage from "./DevProps/pages/Landing";
import EvidencePage from "./DevProps/pages/Evidence/EvidencePage";
import {DocumentPage, UpdateCommercialAreaPage} from "./DevProps/pages";
import SourceEditPage from "./DevProps/pages/Source/SourceEditPage";
import {DocumentService, SourceListService} from "./DevProps/services";
import DocumentModalService from "./DevProps/services/DocumentModalService";
import SourceListPage from "./DevProps/pages/Source/SourceListPage";
import EvidencePageService from "./DevProps/services/EvidencePageService";

const siteMap = {
    LandingPage: {
        title: 'Home',
        path: '/',
        description: 'Landing Page'
    },
    Users: {
        title: 'Users',
        path: '/users',
        description: 'User List Page'
    },
    Posts: {
        title: 'Posts',
        path: '/posts',
        description: 'Post List Page'
    },
    Login: {
        title: 'Login',
        path: '/login',
        description: 'Login Page'
    },
    CommercialArea: {
        title: 'Commercial Area',
        path: '/commercial-area'
    },
    CommercialAreaDetail: {
        title: 'Commercial Area',
        path: '/commercial-area/:id'
    }
}

export const AppRoutes: React.FC = () => {
    return (<>
                <Routes>
                    <Route path="/commercial-area">
                        <Route path={''} element={<PrivateRoute component={UpdateCommercialAreaPage} roles={''}/>}/>
                        <Route path={'create'} element={<PrivateRoute component={UpdateCommercialAreaPage} roles={''}/>}/>
                        <Route path={':id'} element={<PrivateRoute component={UpdateCommercialAreaPage} roles={''}/>}/>
                    </Route>

                    <Route path={siteMap.Login.path} element={<LoginPage/>}/>

                    <Route path={'/evidence'}>
                        <Route path={':id'} element={<EvidencePageService><DocumentModalService><PrivateRoute component={EvidencePage}
                                                                                                              roles={''}/></DocumentModalService></EvidencePageService>}/>
                    </Route>

                    <Route path={'/source'}>
                        <Route path={':id'} element={<PrivateRoute component={SourceEditPage} roles={''}/>}/>
                        <Route path={''} element={<SourceListService><PrivateRoute component={SourceListPage} roles={''}/> </SourceListService>}/>
                    </Route>

                    <Route path={siteMap.LandingPage.path} element={<LandingPage/>}/>

                </Routes>

                <DocumentService>
                    <Routes>
                        <Route path={'/document'}>
                            <Route path={':id'} element={<PrivateRoute component={DocumentPage} roles={''}/>}/>
                        </Route>
                    </Routes>
                </DocumentService>
            </>
    );
}

export default function useRoutes() {
    return siteMap;
}