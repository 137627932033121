import React from 'react';
import {Col, Row, Spinner} from "react-bootstrap";
import PageContainer from "../../pages/PageContainer";

const LoadingPage: React.FC<{ hidden: boolean }> = ({hidden}) => {

    return (
            <PageContainer>
                <Row hidden={hidden}>
                    <Col sm={12} style={{display: 'flex', justifyContent: 'center', paddingTop: '4rem'}}>
                        <Spinner hidden={hidden} animation="grow"/>
                        <Spinner hidden={hidden} animation="grow" className={'mx-3'}/>
                        <Spinner hidden={hidden} animation="grow"/>
                    </Col>
                    <Col sm={12} style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem'}}>
                        <p>Loading Data..</p>
                    </Col>
                </Row>
            </PageContainer>
    );
}

export default LoadingPage;
