import React, {useEffect, useState} from 'react';
import {Form, Modal} from "react-bootstrap";
import API, {API_URL} from "../../../../Request/API";
import {DocumentFile, DocumentText} from "./index";
import {DevPropsDocument, Evidence} from "../../../typings";
import {AxiosResponse} from "axios";

type EvidenceDocumentModalProps = {
    isShown: boolean,
    setIsShown: (state: boolean) => void,
    currentEvidence: Evidence,
    currentDocument: DevPropsDocument
}

const DocumentModal: React.FC<EvidenceDocumentModalProps> = ({isShown, setIsShown, currentEvidence, currentDocument}) => {
    const [showText, setShowText] = useState<boolean>(true);
    const [documentText, setDocumentText] = useState<string>();
    const [isLoadSource, setIsLoadSource] = useState<boolean>(true);

    const paddings = (showText) ? {padding: '1rem'} : {padding: 0};

    const closeModal = () => {
        setShowText(true);
        setIsShown(false);
    }

    const loadImage = () => {
        setIsLoadSource(false);
    }

    useEffect(() => {
        API.get(`${API_URL}/api/document/${currentDocument.document_id}`).then((r: AxiosResponse<{ payload: DevPropsDocument }>) => {
            setDocumentText(r.data.payload.text)
        })

        if (currentDocument.text === null && currentDocument.type !== 'text')
            setShowText(false);
    }, [currentDocument])


//const url = 'http://localhost:3001/api/getResource?url=' + encodeURIComponent('https://www.magdeburg.de/PDF/B_Plan_74_1_1.PDF?ObjSvrID=698&ObjID=7667&ObjLa=1&Ext=PDF&WTR=1&_ts=1623757975');
    const url = `${API_URL}/api/getResource/${currentDocument.document_id}`;

    return (
            <Modal show={isShown} onHide={closeModal} fullscreen>
                <Modal.Header closeButton style={{padding: '1rem'}}>
                    <Modal.Title style={{padding: '1rem'}}>


                        <h1>{currentEvidence.name} {(currentDocument.title) ? `- ${currentDocument.title}` : ''}</h1>

                        {currentDocument.type !== null && <>
                            {currentDocument.type !== 'text' &&
                                    <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Show Source"
                                            checked={!showText}
                                            onClick={() => setShowText(!showText)}
                                    />
                            }
                            {currentDocument.type === 'text' &&
                                    <a target={'_blank'} href={currentDocument.link} rel="noreferrer">Zur Quelle</a>
                            }
                        </>
                        }

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={paddings}>
                    {showText && <DocumentText text={documentText}/>}
                    {!showText && <DocumentFile currentDocument={currentDocument}
                                                isLoadSource={isLoadSource}
                                                showText={showText}
                                                url={url}
                                                onLoad={() => {
                                                    setIsLoadSource(false)
                                                }}
                    />}
                </Modal.Body>
            </Modal>
    );
}

export default DocumentModal;
