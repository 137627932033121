import React, {useContext} from 'react';
import {DocumentModalContext} from "../../../services/DocumentModalService";
import CommercialAreaSearch from "./CommercialAreaSearch";
import Statepicker from "./Statepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faInbox} from "@fortawesome/free-solid-svg-icons";
import {Evidence} from "../../../typings";
import {EvidenceTableContext} from "../../../services/EvidenceTableService";
import {Option} from "react-bootstrap-typeahead/types/types";

export type EvidenceTableRowProps = {
    evidence: Evidence
    groupArea?: Option
}

const EvidenceTableRow: React.FC<EvidenceTableRowProps> = ({evidence, groupArea}) => {
    const {setIsShown, setCurrentDocument, setCurrentEvidence} = useContext(DocumentModalContext);
    const {geoId, states} = useContext(EvidenceTableContext);

    const predictedState = evidence.predicted_state;
    const date = new Date(evidence.document.date.date);

    return (
            <tr key={evidence.evidence_id}>
                <td className="p-3">
                    <div className="d-flex justify-content-between p-2">
                        <span>
                            <FontAwesomeIcon icon={faCalendarAlt}/> {date.toLocaleDateString(undefined, {month: "2-digit", day: "2-digit", year: "numeric"})}
                        </span>
                        <span>
                            <span className="px-3">ID: {evidence.evidence_id} </span>
                            <FontAwesomeIcon icon={faInbox} onClick={() => {
                                setIsShown(true);
                                setCurrentDocument(evidence.document);
                                setCurrentEvidence(evidence);
                            }}/>
                        </span>
                    </div>

                    <hr style={{margin: '0.7rem 0'}}/>
                    <p>{(evidence?.document?.title) ? evidence?.document?.title : evidence?.document?.source?.type}</p>

                    <span className={'mb-0'}
                          style={{fontSize: '13px', opacity: 0.8}}> {evidence.relation_type}</span>
                </td>

                <td colSpan={3}>
                    <table>
                        <tr>
                            <td className="w-50">
                                {evidence.commercial_area !== undefined &&
                                        <CommercialAreaSearch evidenceId={evidence.evidence_id}
                                                              currentCommercialArea={evidence.commercial_area}
                                                              geoId={geoId}
                                                              groupArea={groupArea}
                                        />
                                }
                            </td>

                            <td className="text-end" style={{width: '14.5%'}}>
                                <div className="py-4 px-2 align-middle">{evidence.confidence}</div>
                            </td>
                            <Statepicker stateList={states} predictedState={predictedState} evidenceId={evidence.evidence_id}/>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <p className={'mt-2'} dangerouslySetInnerHTML={{ __html: evidence.matched_text }}></p>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
    );
}

export default EvidenceTableRow;
