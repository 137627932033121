import React, {createContext, useEffect, useState} from 'react';
import API from "../../Request/API";

type SourceTypes = 'RSS' | 'PRESS' | 'NEWS' | 'PLAN' | 'TWITTER';
type SourceType = SourceTypes | string;

export interface SourceListElement {
    source_id: number,
    geo_id: number,
    type: SourceType,
    link: string,
    parameters: string,
    hierarchy_name: string
}

interface ContextType {
    sources: SourceListElement[],
}

export const API_SOURCE_LIST = '/api/source'

export const SourceListContext = createContext<ContextType>({} as ContextType);

export const SourceListService: React.FC = ({children}) => {
    const [sources, setSources] = useState<SourceListElement[]>([]);

    const fetchData = async () => {

        try {
            const response = await API.get(API_SOURCE_LIST, {withCredentials: true});
            setSources(response.data.payload);
        } catch (e) {
            console.warn(e);
        }
    }


    useEffect(() => {
        fetchData().then();
    }, [])

    return (
            <SourceListContext.Provider value={{sources}}>{children}</SourceListContext.Provider>
    );
}