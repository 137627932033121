import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, InputGroup, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faMinus, faPlusCircle, faShare} from "@fortawesome/free-solid-svg-icons";

import {Search} from "../../CommercialArea";
import EvidenceTableRow from "./EvidenceTableRow";
import {EvidenceGroupContext, EvidenceTableContext} from "../../../services";
import {useNavigate, useParams} from "react-router-dom";
import {Evidence} from "../../../typings";
import API from "../../../../Request/API";
import {EvidencePageContext} from "../../../services/EvidencePageService";

const EvidenceTableGroup: React.FC = () => {
    const {collection, groupType, actions} = useContext(EvidenceGroupContext);
    const {availableCommercialAreas} = useContext(EvidenceTableContext);
    const {updateEvidenceState} = useContext(EvidencePageContext);

    const [selectedOption, setSelectedOption] = useState<{ id: number, name: string, alias?: string }>(groupType === 'ID' && collection.commercialAreaId
            ? {id: collection.commercialAreaId, name: collection.name}
            : {id: -1, name: ''}
    );
    const [showGroupElements, setShowGroupElements] = useState<boolean>(false)

    const [options, setOptions] = useState<{ id: number, name: string, alias?: string }[]>([]);

    const navigate = useNavigate();
    const {id} = useParams();
    const ref = useRef(null);

    const handlePreFill = () => {
        const match = availableCommercialAreas.find(cm => cm.name.toLowerCase() === collection.name.toLowerCase())
        // Get matched options and focus search //
        setSelectedOption({id: -1, name: collection.name})

        if (match && ref.current !== null)
            ref.current.focus();
    }

    const handleOnChange = (input: any) => {
        if (input && input.length > 0)
            setSelectedOption(input[0]);

        if (input[0] === undefined) // no input
            return

        actions.fetchNewArea(input[0].id)
                .then()
    }

    // Add options if there are ready
    useEffect(() => {
        const newOptions = availableCommercialAreas.map((cm) => ({id: cm.commercial_area_id, name: cm.name, alias: cm.alias}));
        setOptions(newOptions);
    }, [availableCommercialAreas])

    useEffect(() => {
        setSelectedOption(groupType === 'ID' && collection.commercialAreaId
                ? {id: collection.commercialAreaId, name: collection.name}
                : {id: -1, name: ''})
    }, [collection])

    const sumConfidences = (collection.collection.length > 0)
            ? collection.collection.map(e => e.confidence).reduce((a, b) => Number(a) + Number(b))
            : 0;

    const onConfirm = (col: Evidence[]) => {
        const evidenceIds = col.map((e) => {
            return e.evidence_id
        });

        API.post('/api/evidence/markAsInvalid', {'evidence_ids': evidenceIds}, {withCredentials: true}).then((res) => {
            if (res.status === 200) {
                updateEvidenceState(evidenceIds, 'INVALID')
                setShowGroupElements(false);
            }
        })
    }

    return (
            <Table striped bordered hover size="sm">
                <tbody>
                <tr>
                    <th colSpan={1} className="align-middle" style={{width: '40%'}} onClick={() => setShowGroupElements(!showGroupElements)}>
                        <div className="d-flex justify-content-between p-2">
                            <span>{collection.name}</span>
                            <span className="text-nowrap">{collection.collection.length} Documents</span>
                        </div>
                    </th>
                    <th style={{width: '30%'}} className="align-middle">
                        <InputGroup size="sm">
                            <Search defaultInputValue={selectedOption}
                                    onChange={handleOnChange}
                                    onInputChange={(q) => {
                                        setSelectedOption({id: -1, name: q})
                                    }}
                                    options={options}
                                    innerRef={ref}
                            />

                            <Button variant="outline-secondary" title="Copy evidence name to input"
                                    onClick={handlePreFill}>
                                <FontAwesomeIcon icon={faShare}
                                />
                            </Button>

                            {selectedOption?.id > 0 &&
                                    <Button variant="outline-info" title="Edit Commercial Area"
                                            onClick={() => navigate(`/commercial-area/${selectedOption.id}`)}
                                    >
                                        <FontAwesomeIcon icon={faEdit}/>
                                    </Button>
                            }

                            <Button variant="outline-success" title="Add new commercial area"
                                    onClick={() => navigate(`/commercial-area?geo_id=${id ?? ''}&name=${selectedOption?.name ?? ''}&evidence_id=${collection.collection.map(e => e.evidence_id).toString()}`)}
                            >
                                <FontAwesomeIcon icon={faPlusCircle}/>
                            </Button>
                        </InputGroup>
                    </th>
                    <th className="align-middle text-end p-2">Conf. {sumConfidences}</th>
                    <th className="align-middle text-center">
                        <div className="d-flex justify-content-between p-2  align-items-baseline">
                            <div> Predicted State</div>
                            <Button size="sm"
                                    variant="outline-danger"
                                    title="Mark all Evidences as INVALID."
                                    onClick={() => window.confirm('Are you sure you wish to delete this item?') ? onConfirm(collection.collection) : () => {
                                    }}
                            >
                                <FontAwesomeIcon icon={faMinus}/>
                            </Button>
                        </div>
                    </th>
                </tr>
                {showGroupElements && collection.collection.map((evidence, key) =>
                        <EvidenceTableRow key={key}
                                          evidence={evidence}
                                          groupArea={(selectedOption?.id !== -1) ? selectedOption : undefined}
                        />
                )}
                </tbody>
            </Table>
    );
}

export default EvidenceTableGroup;
