import React, {useContext, useEffect, useState} from 'react';
import {Evidence} from "../../../typings";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSortDown, faSortUp} from "@fortawesome/free-solid-svg-icons";
import {Pagination, Stack, Table} from "react-bootstrap";
import {EvidenceTableCollection} from "./EvidenceTableCollection";
import {EvidencePageContext} from "../../../services/EvidencePageService";
import usePagination from "../../../hooks/usePagination";

type SortType = 'ASC' | 'DESC' | undefined;
export type GroupType = 'NAME' | 'ID' | 'INVALID';

type EvidenceTableProps = {
    title?: string;
    evidences: Evidence[];
    groupBy: GroupType,
}

export type EvidenceCollection = {
    commercialAreaId?: number,
    name: string,
    collection: Evidence[];
}

const pageSize = 25;

export type EvidenceData = EvidenceCollection[];

const EvidenceTable: React.FC<EvidenceTableProps> = ({title, evidences, groupBy}) => {
    const [sort, setSort] = useState<{ row: string, type: SortType }>({row: 'name', type: "ASC"});
    const [data, setData] = useState<EvidenceData>();
    const [currentPage, setCurrentPage] = useState<number>(1);

    const {availableCommercialAreas} = useContext(EvidencePageContext);

    // Paginate data
    const paginationRange = usePagination({
        count: data?.length || 0,
        page: currentPage,
        rowsPerPage: pageSize,
        rowsPerPageOptions: [10, pageSize, 50]
    })
    const currentData = data?.slice((currentPage - 1) * pageSize, currentPage * pageSize);


    const groupByName = () => {
        const evidenceData: EvidenceData = [];

        // @ts-ignore
        const distinctNames = [...new Set(evidences.map((e) => e.name))];

        distinctNames.forEach((distinctName) => {
            const collection = evidences.filter((e) => {
                return e.name === distinctName
            })
            evidenceData.push({name: distinctName, collection: collection})
        })
        return evidenceData;
    }

    const groupByID = () => {
        const evidenceData: EvidenceData = [];
        // @ts-ignore
        const distinctIds = [...new Set(evidences.map((e) => e.commercial_area_id))];

        distinctIds.forEach((distinctId) => {
            const collection = evidences.filter((e) => {
                return e.commercial_area_id === distinctId
            })
            if (collection && collection[0].commercial_area)
                evidenceData.push({
                    name: collection[0].commercial_area.name,
                    commercialAreaId: collection[0].commercial_area.commercial_area_id,
                    collection: collection
                })
        })
        return evidenceData;
    }

    useEffect(() => {

        let evidenceData: EvidenceCollection[] = [];

        if (groupBy === 'NAME') {
            evidenceData = groupByName();
        } else if (groupBy === 'ID') {
            evidenceData = groupByID()
        }

        if (sort.row === 'name') {
            const d = (sort.type === 'ASC')
                    ? [...evidenceData.sort((a, b) => {
                        if (a.name > b.name)
                            return 1;
                        if (a.name < b.name)
                            return -1;
                        return 0
                    })]
                    : [...evidenceData.sort((a, b) => {
                        if (a.name > b.name)
                            return -1;
                        if (a.name < b.name)
                            return 1;
                        return 0
                    })]
            setData(d)
        }


        if (sort.row === 'confidence') {
            const d = (sort.type === 'ASC')
                    ? [...evidenceData.sort((a, b) => {
                        const conf_a = a.collection.map(e => e.confidence).reduce((num_a, num_b) => {
                            return Number(num_a) + Number(num_b)
                        })
                        const conf_b = b.collection.map(e => e.confidence).reduce((num_a, num_b) => {
                            return Number(num_a) + Number(num_b)
                        })

                        if (conf_a > conf_b)
                            return 1;
                        if (conf_a < conf_b)
                            return -1;
                        return 0
                    })]
                    : [...evidenceData.sort((a, b) => {
                        const conf_a = a.collection.map(e => e.confidence).reduce((num_a, num_b) => {
                            return Number(num_a) + Number(num_b)
                        })
                        const conf_b = b.collection.map(e => e.confidence).reduce((num_a, num_b) => {
                            return Number(num_a) + Number(num_b)
                        })

                        if (conf_a > conf_b)
                            return -1;
                        if (conf_a < conf_b)
                            return 1;
                        return 0
                    })]
            setData(d)
        }

    }, [evidences, sort, currentPage])


    const toggleSort = (type: string) => {
        if (sort.type === 'ASC')
            setSort({row: type, type: "DESC"});
        else if (sort.type === 'DESC')
            setSort({row: type, type: "ASC"});
    }

    return (
            <>
                {title && <h1>{title}</h1>}

                <Table striped bordered hover size="sm" className="my-3">
                    <thead>
                    <tr>
                        <th colSpan={1} className="align-middle" onClick={() => toggleSort('name')}>
                            Evidence Name {(sort.row !== 'name')
                                ? ''
                                : (sort.type === 'ASC') ? <FontAwesomeIcon icon={faSortDown}/> : <FontAwesomeIcon icon={faSortUp}/>}
                        </th>
                        <th colSpan={1} className="align-middle" onClick={() => toggleSort('confidence')}>
                            Confidence {(sort.row !== 'confidence')
                                ? ''
                                : (sort.type === 'ASC')
                                        ? <FontAwesomeIcon icon={faSortDown}/>
                                        : <FontAwesomeIcon icon={faSortUp}/>}
                        </th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colSpan={12}>
                            {currentData && <EvidenceTableCollection evidenceData={currentData} groupType={groupBy}/>}
                        </td>
                    </tr>
                    </tbody>
                </Table>
                {paginationRange.showPagination ? (
                                <Stack gap={2}
                                       className="col-md-5 mx-auto d-flex justify-content-center">
                                    <Pagination size={"sm"}>
                                        {paginationRange.showFirst
                                                ? <Pagination.First
                                                        onClick={() => setCurrentPage(1)}/>
                                                : <></>}
                                        {paginationRange.showPrevious
                                                ? <Pagination.Prev
                                                        onClick={() => setCurrentPage(currentPage - 1)}/>
                                                : <></>}
                                        {paginationRange.pages.map(pageNumber =>
                                                <Pagination.Item key={pageNumber}
                                                                 active={currentPage === pageNumber}
                                                                 onClick={() => {
                                                                     setCurrentPage(pageNumber);
                                                                 }}>
                                                    {pageNumber}
                                                </Pagination.Item>
                                        )}

                                        {paginationRange.showNext
                                                ? <Pagination.Next href={'#ceo-table'}
                                                                   data-scroll='{"offset": 0}'
                                                                   onClick={() => setCurrentPage(currentPage + 1)}/>
                                                : <></>}
                                        {paginationRange.showLast
                                                ? <Pagination.Last
                                                        onClick={() => setCurrentPage(paginationRange.pageCount)}/>
                                                : <></>}
                                    </Pagination>
                                </Stack>
                        )
                        : <></>}
            </>
    );
}

export default EvidenceTable;
