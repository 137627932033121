import React from "react";
import {Hierarchy, HierarchyTree} from "../../typings";

const HierarchyBradCrumbs: React.FC<{ hierarchyTree: HierarchyTree }> = (props: { hierarchyTree: HierarchyTree }) => {
    const {hierarchyTree} = props;

    let tmpTree = [...hierarchyTree];
    const lowestLevel = tmpTree.pop();

    return (
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-scroll">
                    {tmpTree.map((hierarchy: Hierarchy) => {
                        return <li key={hierarchy.id}
                                   className="breadcrumb-item"
                        >
                            {hierarchy.name}
                        </li>
                    })}

                    <li key={lowestLevel?.id} className="breadcrumb-item active" aria-current="page">
                        {lowestLevel?.name}
                    </li>
                </ol>
            </nav>
    );
}

export default HierarchyBradCrumbs;