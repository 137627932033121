import React, {Dispatch, SetStateAction, useMemo, useState} from 'react';
import {DevPropsDocument, Evidence} from "../typings";

interface DocumentModalContextType {
    isShown: boolean,
    currentDocument: DevPropsDocument | undefined,
    currentEvidence: Evidence | undefined,
    setIsShown: Dispatch<SetStateAction<boolean>>
    setCurrentDocument: Dispatch<SetStateAction<DevPropsDocument | undefined>>
    setCurrentEvidence: Dispatch<SetStateAction<Evidence | undefined>>
}

export const DocumentModalContext = React.createContext<DocumentModalContextType>({} as DocumentModalContextType);

const DocumentModalService: React.FC = ({children}) => {
    const [isShown, setIsShown] = useState<boolean>(false);
    const [currentDocument, setCurrentDocument] = useState<DevPropsDocument | undefined>()
    const [currentEvidence, setCurrentEvidence] = useState<Evidence | undefined>();

    const memorizedData = useMemo(() => ({
        isShown, currentDocument, currentEvidence, setIsShown, setCurrentDocument, setCurrentEvidence
    }), [currentDocument, currentEvidence, isShown])

    return (
            <DocumentModalContext.Provider value={memorizedData}>{children}</DocumentModalContext.Provider>
    );
}

export default DocumentModalService;
