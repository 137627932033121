import React, {useContext} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {ErrorContext} from "./ErrorHandler";

export type BagType = 'authentication' | 'server' | string

interface IErrorBagFactoryProps {
    responseData: any
}

const ErrorBagFactory: React.FC<IErrorBagFactoryProps> = ({responseData}) => {
    const errContext = useContext(ErrorContext);

    const type: BagType = Object.keys(responseData)[0];

    switch (type) {
        case "authentication":
            return (
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                        <FontAwesomeIcon icon={faExclamationTriangle}/>&nbsp;
                        {responseData[type]}

                        <button type="button"
                                className="btn-close"
                                data-bs-dismiss="alert"
                                aria-label="Close"
                                onClick={errContext.resetErrorBag}>
                        </button>
                    </div>)
        default:
            return (
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <FontAwesomeIcon icon={faExclamationTriangle}/>&nbsp;
                        {responseData[type] !== undefined && responseData[type] !== false ? responseData[type] : 'Es ist ein Fehler aufgetreten.'}

                        <button type="button"
                                className="btn-close"
                                data-bs-dismiss="alert"
                                aria-label="Close"
                                onClick={errContext.resetErrorBag}>
                        </button>
                    </div>)
    }
}

export default ErrorBagFactory;
