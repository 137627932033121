import React from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";

interface INavLinkProps {
    link: string,
    description: string
}

interface INoDataFoundProps {
    title: string,
    navigation?: INavLinkProps,
    description?: string
}

const NoDataFound: React.FC<INoDataFoundProps> = ({title, navigation, description}) => {

    return (
            <section>
                <Container className={'d-flex flex-column'}>
                    <Row className={'align-items-center justify-content-center gx-0 min-vh-80'}>
                        <Col md={7} lg={6} className="py-8 py-md-11">
                            <h1 className="mb-0 fw-bold text-center">{title}</h1>
                            {description && <p className="mb-6 text-center text-muted">{description}</p>}
                            {navigation && <Button variant="secondary" className="w-100 mt-5" href={navigation.link}>{navigation.description}</Button>}
                        </Col>
                    </Row>
                </Container>
            </section>
    );
}

export default NoDataFound;
