import React from 'react';
import {DevPropsDocument} from "../../typings";
import BadgeFactory from "../BadgeFactory";


export const ResultList: React.FC<{ docs: DevPropsDocument[] }> = ({docs}) =>
        <div className="list-group list-group-flush mt-4">
            {Object.values(docs).map(document => <div className="list-group-item d-flex align-items-center">
                <div className="me-auto">
                    <div>
                        {document.link && <BadgeFactory type={document.type} link={document.link}/>}

                        <strong className="mb-1">{document.title || "Kein Titel"}</strong> <br/>
                        <a href={document.link}>{document.link}</a>
                    </div>

                    <p className="fs-sm text-muted mb-0" style={{whiteSpace: 'break-spaces'}}>
                        {document.text.length > 400 ? document.text.slice(0, 400) + '...' : document.text}
                    </p>
                </div>
            </div>)}
        </div>