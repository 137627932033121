import React, {createContext, useEffect, useMemo, useState} from 'react';
import API from "./API";
import {AxiosResponse} from "axios";
import {useLocation} from "react-router-dom";

interface IErrorContext {
    errorBag: AxiosResponse[],
    resetErrorBag: () => void;
}

export const ErrorContext = createContext<IErrorContext>({} as IErrorContext);

const ErrorHandler: React.FC = ({children}) => {
    const [errorBag, setErrorBag] = useState<AxiosResponse[]>([]);
    const location = useLocation();

    function resetErrorBag() {
        setErrorBag([]);
    }

    // Register Interceptors once
    useEffect(() => {
        API.interceptors.response.use((response) => {
            return response;
        }, function (error) {
            if ([400, 404, 500].includes(error.response.status)) {
                setErrorBag([error.response, ...errorBag]);
            }
            return error;
        })
    }, [])


    useEffect(() => {
        // Reset error bag on page change
        setErrorBag([]);
    }, [location.key]);

    const memorizedData = useMemo(() => {
        return {errorBag: errorBag, resetErrorBag: resetErrorBag}
    }, [errorBag]);

    return (
            <ErrorContext.Provider value={memorizedData}>
                {children}
            </ErrorContext.Provider>
    );
}

export default ErrorHandler;
