import React, {createContext, useEffect, useState} from 'react';
import {CommercialArea} from "../pages/CommercialArea/CommercialAreaPage";
import API from "../../Request/API";
import {AxiosResponse} from "axios";
import {CommercialAreasAxiosResponse} from "../pages/CommercialArea/CommercialAreaDetailPage";

type ServiceProps = {
    children: React.ReactNode,
    initGeoId: number,
    initStates: string[]
}

interface ContextType {
    geoId: number,
    states: string[],
    availableCommercialAreas: CommercialArea[]
}

export const EvidenceTableContext = createContext<ContextType>({} as ContextType);

export const EvidenceTableService: React.FC<ServiceProps> = ({children, initGeoId, initStates}) => {
    const [states, setStates] = useState<string[]>(initStates);
    const [geoId, setGeoId] = useState<number>(initGeoId);
    const [availableCommercialAreas, setAvailableCommercialAreas] = useState<CommercialArea[]>([]);

    function fetchAvailableCommercialAreas() {
        API.get(`/api/commercialArea?geo_id=${geoId}`).then((response: AxiosResponse<CommercialAreasAxiosResponse>) => {
            const payload = response.data.payload.map((cm) => cm.commercialArea);
            setAvailableCommercialAreas(payload);
        })
    }

    function fetchAvailableStates() {
        API.get(`/api/evidence/states`).then((response: AxiosResponse<{ payload: string[] }>) => {
            setStates(response.data.payload)
        })
    }

    useEffect(() => {
        fetchAvailableCommercialAreas();
        fetchAvailableStates();
    }, [])

    return (
            <EvidenceTableContext.Provider value={{states, geoId, availableCommercialAreas}}>{children}</EvidenceTableContext.Provider>
    );
}