import React, {useMemo} from 'react';
import {EvidenceData, GroupType} from "./EvidenceTable";
import EvidenceTableGroup from "./EvidenceTableGroup";
import EvidenceGroupService from "../../../services/EvidenceGroupService";

type EvidenceTableCollectionProps = { evidenceData: EvidenceData, groupType: GroupType }

export const EvidenceTableCollection: React.FC<EvidenceTableCollectionProps> = ({evidenceData, groupType}) => {
    const memorized = useMemo(() => ({evidenceData}), [evidenceData])
    return (
            <>
                {memorized.evidenceData.map((ev, idx) =>
                        <EvidenceGroupService key={'service_' + idx} initCollection={ev} groupType={groupType}>
                            <EvidenceTableGroup key={'group_' + idx}/>
                        </EvidenceGroupService>
                )}
            </>
    );
}