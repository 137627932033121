import React from "react";

import {ToastContainer} from "react-toastify";
import {AppRoutes} from "../../Routes";
import {NavigationBar} from "../components/Navigation/NavigationBar";
import {AuthService} from "../../Auth/services/AuthService";
import ErrorHandler from "../../Request/ErrorHandler";

/** CSS */
import '../assets/css/libs.bundle.css'
import "leaflet/dist/leaflet.css";
import '../assets/css/error.css'
import '../assets/css/app.css'
import '../assets/css/theme.bundle.css'
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const App: React.FC = () => {
    return (
            <ErrorHandler>
                <AuthService>
                    <ToastContainer
                            position="bottom-right"
                            autoClose={3000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                    />

                    <div>
                        <NavigationBar/>
                        <AppRoutes/>
                    </div>
                </AuthService>
            </ErrorHandler>
    );
}

export default App;
