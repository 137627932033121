import React, {JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useContext, useEffect, useState} from 'react';
import {Breadcrumb, Container} from "react-bootstrap";
import {ErrorContext} from "../../Request/ErrorHandler";
import ErrorBagFactory from "../../Request/ErrorBagFactory";

export type BreadCrumbItem = {
    url: string,
    name: string
}

export type PageContainerProps = {
    children: ReactElement<any, string | JSXElementConstructor<any>> | string | number | {} | Iterable<ReactNode> | ReactPortal | boolean | null | undefined
    breadcrumbs?: BreadCrumbItem[]
}

const PageContainer: React.FC<PageContainerProps> = ({children, breadcrumbs}) => {
    const [currentBreadcrumbs, setCurrentBreadCrumbs] = useState<BreadCrumbItem[]>()
    const [currentLocation, setCurrentLocation] = useState<BreadCrumbItem>()
    const [isReady, setIsReady] = useState<boolean>(false); // prevent multiple pops in dev mode
    const errContext = useContext(ErrorContext);

    useEffect(() => {
        if (!breadcrumbs)
            return;

        if (!isReady) {
            setCurrentLocation(breadcrumbs.pop())
            setCurrentBreadCrumbs(breadcrumbs)
            setIsReady(true);
        }
    }, [breadcrumbs, isReady])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [errContext.errorBag])

    const tableContainerStyles = {
        maxWidth: '90vw'
    };

    return (
            <>
                <section style={{backgroundColor: 'white'}}>
                    <Container fluid style={tableContainerStyles}>
                        <Breadcrumb>
                            {currentBreadcrumbs && currentBreadcrumbs.map((breadCrumb: BreadCrumbItem) =>
                                    <Breadcrumb.Item key={breadCrumb.name} href={breadCrumb.url}>{breadCrumb.name}</Breadcrumb.Item>
                            )}
                            {currentLocation && <Breadcrumb.Item key={currentLocation.name} className={'active'}>{currentLocation.name}</Breadcrumb.Item>}
                        </Breadcrumb>
                    </Container>
                </section>

                {errContext.errorBag.length > 0 &&
                        <section style={{backgroundColor: 'white'}}>
                            <Container>
                                {errContext.errorBag.map(err => <ErrorBagFactory responseData={err.data}/>)}
                            </Container>
                        </section>
                }

                <section>
                    <Container fluid style={tableContainerStyles}>
                        <Container fluid className={"pt-3 pt-md-4 pb-4 mb-md-4"}>
                            {children}
                        </Container>
                    </Container>
                </section>
            </>
    );
}

export default PageContainer;
