import React, {createContext, useCallback, useMemo, useState} from 'react';
import {Evidence} from "../typings";
import {AxiosResponse} from "axios";
import API from "../../Request/API";
import {CommercialAreaArrayAxiosResponse} from "../../Request/ResponseTypes";
import {CommercialArea, HierarchyTree} from "../pages/CommercialArea/CommercialAreaPage";
import {LatLngTuple} from "leaflet";
import {CommercialAreasAxiosResponse} from "../pages/CommercialArea/CommercialAreaDetailPage";

interface ContextType {
    evidences: Evidence[],
    coordinates?: LatLngTuple,
    currentCommercialArea?: CommercialArea,
    availableCommercialAreas: CommercialArea[],
    hierarchyTree?: HierarchyTree,
    fetchData: (geoId: number) => Promise<void>,
    removeEvidencesByIds: (idsToExclude: number[]) => void,
    markAsInvalid: (idsToSetInvalid: number[]) => void,
    updateEvidenceState: (idsToChange: number[], newState: string) => void,
    updateCommercialAreaOfEvidences: (idsToChange: number[], commercialAreaId: number) => void,
}

export const EvidencePageContext = createContext<ContextType>({} as ContextType);

const EvidencePageService: React.FC = ({children}) => {
    const [evidences, setEvidences] = useState<Evidence[]>([]);
    const [coordinates, setCoordinates] = useState<LatLngTuple | undefined>();
    const [availableCommercialAreas, setAvailableCommercialAreas] = useState<CommercialArea[]>([]);
    const [currentCommercialArea, setCurrentCommercialArea] = useState<CommercialArea>();
    const [hierarchyTree, setHierarchyTree] = useState<HierarchyTree>();
    const [error, setError] = useState(null)

    async function fetchAvailableCommercialAreas(geoId: number) {
        API.get(`/api/commercialArea?geo_id=${geoId}`).then((response: AxiosResponse<CommercialAreasAxiosResponse>) => {
            const payload = response.data.payload.map((cm) => cm.commercialArea);
            setAvailableCommercialAreas(payload);
        })
    }

    async function fetchCommercialAreaByGeoId(commercialAreaId: number) {
        const response: AxiosResponse<CommercialAreaArrayAxiosResponse> = await API.get(`/api/commercialArea?geo_id=${commercialAreaId}&limit=1`, {withCredentials: true});
        const payload = response.data.payload.shift();

        setCurrentCommercialArea(payload?.commercialArea);

        if (payload?.coordinates)
            setCoordinates([payload.coordinates.lon, payload.coordinates.lat])
    }

    async function fetchHierarchyTree(geoId: number) {
        const response: AxiosResponse<{ payload: HierarchyTree }> = await API.get(`/api/hierarchy/tree/${geoId}`, {withCredentials: true});
        const hierarchyTree = response.data.payload;
        setHierarchyTree(hierarchyTree);
    }

    const fetchData = useCallback(async (geoId: number) => {
        try {
            const response: AxiosResponse<{ payload: Evidence[] }> = await API.get(`/api/evidence?geo_id=${geoId}`, {withCredentials: true});
            setEvidences(response.data.payload);

            // fetch data only if evidences are fetched before
            if (response.data.payload.length > 0) {
                await fetchAvailableCommercialAreas(geoId);
                await fetchCommercialAreaByGeoId(geoId);
                await fetchHierarchyTree(geoId);
            }
            setError(null);
        } catch (e) {
            setError(error);
        }
    }, [])

    const removeEvidencesByIds = useCallback((idsToExclude: number[]) => {
        setEvidences(evidences.filter((e) => !idsToExclude.includes(e.evidence_id)));
    }, [evidences])

    const markEvidencesAsInvalid = useCallback((idsToSetInvalid: number[]) => {
        const tmpEvidences = evidences.map((e) => {
            if (idsToSetInvalid.includes(e.evidence_id))
                e.predicted_state = 'INVALID';
            return e;
        })
        setEvidences(tmpEvidences);
    }, [evidences])

    const updateEvidenceState = useCallback((idsToChange: number[], newState: string) => {
        const tmpEvidences = evidences.map((e) => {
            if (idsToChange.includes(e.evidence_id))
                e.predicted_state = newState;
            return e;
        })
        setEvidences(tmpEvidences);
    }, [evidences]);

    const updateCommercialAreaOfEvidences = useCallback((idsToChange: number[], commercialAreaId: number) => {
        const tmpEvidences = evidences.map((e) => {
            if (idsToChange.includes(e.evidence_id))
                e.commercial_area_id = commercialAreaId
            return e;
        })
        setEvidences(tmpEvidences);
    }, [evidences])

    const memorizedData = useMemo(() => ({
        evidences: evidences,
        coordinates: coordinates,
        hierarchyTree: hierarchyTree,
        currenCommercialArea: currentCommercialArea,
        availableCommercialAreas: availableCommercialAreas,
        fetchData: fetchData,
        removeEvidencesByIds: removeEvidencesByIds,
        markAsInvalid: markEvidencesAsInvalid,
        updateEvidenceState: updateEvidenceState,
        updateCommercialAreaOfEvidences: updateCommercialAreaOfEvidences,
    }), [evidences, coordinates, hierarchyTree, currentCommercialArea, availableCommercialAreas, fetchData, removeEvidencesByIds, markEvidencesAsInvalid, updateEvidenceState])

    return <EvidencePageContext.Provider value={memorizedData}>{children}</EvidencePageContext.Provider>;
}

export default EvidencePageService;
