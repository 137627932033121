import React, {useRef} from 'react';
import {Typeahead} from "react-bootstrap-typeahead";
import {Option} from "react-bootstrap-typeahead/types/types";

export type CommercialAreaSearchProps = {
    defaultInputValue: { id: number, name: string } | undefined,
    onChange: (input: any) => void,
    options: Option[],
    onInputChange: (input: string) => void,
    innerRef: React.MutableRefObject<any> | undefined
}


export const Search: React.FC<CommercialAreaSearchProps> = (props: CommercialAreaSearchProps) => {

    let reference = useRef(null);

    if (props.innerRef)
        reference = props.innerRef;

    return (
            <Typeahead
                    id="commercial-area-search"
                    style={{width: "unset"}}
                    labelKey="name"
                    onChange={props.onChange}
                    options={props.options}
                    placeholder="Search a commercial area..."
                    selected={(props.defaultInputValue !== undefined) ? [props.defaultInputValue] as Option[] : [] as Option[]}
                    onInputChange={props.onInputChange}
                    ref={reference}
                    renderMenuItemChildren={(option) => {
                        return (
                                <div key={option.id}>
                                    <span className="fw-bold">{option.name} </span>
                                    {option.alias && <span><small>({option.alias})</small></span>}
                                </div>
                        )
                    }
                    }
            />
    );
}
