import React, {useContext, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import PageContainer from "../PageContainer";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import EvidenceTable from "../../components/Evidence/Table/EvidenceTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarked} from "@fortawesome/free-solid-svg-icons";
import HierarchyBradCrumbs from "../../components/CommercialArea/HierarchyBradCrumbs";
import {DocumentModalContext} from "../../services/DocumentModalService";
import DocumentModal from "../../components/Evidence/Modal/DocumentModal";
import {EvidenceTableService} from "../../services";
import NoDataFound from "../../components/NoDataFound";
import {EvidencePageContext} from "../../services/EvidencePageService";
import LoadingPage from "../../components/Loading/LoadingPage";

type TabKey = 'pending' | 'invalid' | 'known';

const EvidencePage: React.FC = () => {
    const [stateList, setStateList] = useState<string[]>([]);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [tabKey, setTabKey] = useState<TabKey>('pending');

    const {evidences, coordinates, hierarchyTree, fetchData} = useContext(EvidencePageContext);
    const {isShown, currentEvidence, setIsShown} = useContext(DocumentModalContext);

    const {id} = useParams();
    const hierarchyIds = hierarchyTree?.map((h) => h.id)

    const currentDocument = (currentEvidence !== undefined) ? currentEvidence.document : undefined;
    const currentHierarchy = hierarchyTree ? hierarchyTree[hierarchyTree.length - 1] : undefined;
    const urlToGoogleMaps = (currentHierarchy?.coords) ? `https://www.google.com/maps/@${currentHierarchy.coords.lat},${currentHierarchy.coords.lon},${13}z` : undefined;

    // Filter data by pending, known and invalid
    const assignedEvidences = evidences.filter((e) => e.commercial_area_id !== null && e.predicted_state !== 'INVALID');
    const notAssignedEvidences = evidences.filter(e => e.commercial_area_id === null && e.predicted_state !== 'INVALID');
    const invalidEvidences = evidences.filter(e => e.predicted_state === 'INVALID');

    const memorizedData = useMemo(() => ({isShown, setIsShown}), [isShown])

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            fetchData(Number(id))
                    .then(() => setIsReady(true))
                    .finally(() => setIsLoading(false));
        }
    }, [id]);

    useEffect(() => {
    }, [evidences]);

    // Show Loading page
    if (isLoading)
        return <LoadingPage hidden={false}/>

    // No data
    if (isReady && evidences.length === 0)
        return <NoDataFound title={'No evidences found.'} navigation={{link: '/source', description: 'Back to sources'}}/>

    return (<>
                {isReady && evidences.length > 0 && <EvidenceTableService initGeoId={Number(id)} initStates={stateList}>
                    {currentEvidence && currentDocument &&
                            <DocumentModal isShown={memorizedData.isShown}
                                           setIsShown={memorizedData.setIsShown}
                                           currentEvidence={currentEvidence}
                                           currentDocument={currentDocument}
                            />
                    }

                    <PageContainer>

                        {hierarchyTree && <h1> {hierarchyTree[hierarchyTree.length - 1].name}
                            {coordinates && <a target={"_blank"} href={urlToGoogleMaps} className={'ms-8'} style={{opacity: 0.4}} rel="noreferrer">
                                <FontAwesomeIcon icon={faMapMarked}/>
                            </a>
                            }
                        </h1>
                        }

                        <Row className="mb-4 mb-md-4">
                            {hierarchyTree && <Col sm={12}><HierarchyBradCrumbs hierarchyTree={hierarchyTree}/></Col>}
                        </Row>

                        <Tabs id="evidence-tabs"
                              activeKey={tabKey}
                              onSelect={k => setTabKey(k)}
                        >

                            <Tab eventKey="pending" title="Pending">

                                {notAssignedEvidences.length > 0
                                        ? <EvidenceTable evidences={notAssignedEvidences} groupBy="NAME"/>
                                        : <p className="m-3">There are no evidences to be processed.</p>
                                }

                            </Tab>


                            <Tab eventKey="known" title="Known" disabled={assignedEvidences.length === 0}>
                                <EvidenceTable evidences={assignedEvidences} groupBy={'ID'}/>
                            </Tab>


                            <Tab eventKey="invalid" title="Invalid" disabled={invalidEvidences.length === 0}>
                                <EvidenceTable evidences={invalidEvidences} groupBy={'NAME'}/>
                            </Tab>


                        </Tabs>
                    </PageContainer>
                </EvidenceTableService>
                }
            </>
    );
}

export default EvidencePage;