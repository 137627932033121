import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import App from "./DevProps/pages/App";

const apiUrl = process.env.NODE_ENV === 'production'
               ? process.env.REACT_APP_PROD_API_URL
               : process.env.REACT_APP_DEV_API_URL;

ReactDOM.render(
    <Router>
        <App/>
    </Router>,
    document.getElementById('root'));

export default apiUrl;