// @ts-ignore
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import React, {useEffect, useState} from "react";
import {Marker, Popup, useMap} from "react-leaflet";
import {Icon, LatLng} from "leaflet";

type PositionMarkerProps = {
    markerPosition: LatLng,
    initZoomLevel?: number
}

const PositionMarker: React.FC<PositionMarkerProps> = ({markerPosition, initZoomLevel}) => {
    const [initRender, setInitRender] = useState<boolean>(true);
    const map = useMap();

    // update position and zoom level
    useEffect(() => {
        const zoom = initRender ? initZoomLevel : map.getZoom();
        map.setView(markerPosition, zoom);

        if (initRender)
            setInitRender(false)
    }, [map, markerPosition, initZoomLevel, initRender])

    //useMap().setZoom(13);

    return (
            <Marker position={markerPosition}
                    icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}>
                <Popup>
                    A pretty CSS3 popup. <br/> Easily customizable.
                </Popup>
            </Marker>
    )
}

export default PositionMarker;