import React, {useContext, useEffect, useState} from 'react';
import {EvidenceCollection, GroupType} from "../components/Evidence/Table/EvidenceTable";
import API from "../../Request/API";
import {EvidenceTableContext} from "./EvidenceTableService";
import {EvidencePageContext} from "./EvidencePageService";

type EvidenceGroupContextProps = {
    children: React.ReactNode,
    initCollection: EvidenceCollection,
    groupType: GroupType
}

interface IEvidenceGroupContext {
    collection: EvidenceCollection,
    groupType: GroupType,
    actions: {
        fetchNewArea: (id: number) => Promise<{ success: boolean, msg: string }>
    }
}

export const EvidenceGroupContext = React.createContext({} as IEvidenceGroupContext);

const EvidenceGroupService: React.FC<EvidenceGroupContextProps> = ({children, initCollection, groupType}) => {
    const [collection, setCollection] = useState<EvidenceCollection>({...initCollection})

    const tableContext = useContext(EvidenceTableContext);
    const {updateCommercialAreaOfEvidences} = useContext(EvidencePageContext);

    const updateCommercialArea = async (id: number) => {
        let status = {success: false, msg: 'Error. Try it again.'}

        // find existing area
        const newArea = tableContext.availableCommercialAreas.find((cm) => cm.commercial_area_id === id);

        if (newArea === undefined) {
            status = {success: false, msg: 'Area does not match with area options.'}
            return status  // throw error instead?!
        }

        for (const evidenceId of (collection.collection.map((e) => e.evidence_id))) {
            const idx = collection.collection.findIndex(e => e.evidence_id === evidenceId)

            const res = await API.post('/api/evidence/update', {evidence_id: evidenceId, commercial_area_id: id});

            if (res.status === 200) {
                collection.collection[idx].commercial_area_id = id;
                collection.collection[idx].commercial_area = newArea;

                // need to trigger rerender. more infos here: https://stackoverflow.com/questions/56266575/why-is-usestate-not-triggering-re-render
                collection.collection = [...collection.collection];
                setCollection({...collection});

                status = {success: true, msg: 'Updated successfully.'}
            }
        }
        updateCommercialAreaOfEvidences(collection.collection.map((e) => e.evidence_id), id);
        return status;
    }

    const actions = {
        fetchNewArea: updateCommercialArea
    }

    useEffect(() => {
        setCollection(initCollection)
    }, [initCollection])


    return <EvidenceGroupContext.Provider value={{collection, groupType, actions}}>{children}</EvidenceGroupContext.Provider>
}

export default EvidenceGroupService;
