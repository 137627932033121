import React, {useContext, useEffect, useRef, useState} from 'react';
import {Form} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import API from "../../../../Request/API";
import {AxiosResponse} from "axios";
import {CommercialArea} from "../../../pages/CommercialArea/CommercialAreaPage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {Option} from "react-bootstrap-typeahead/types/types";
import {EvidenceTableContext} from "../../../services";
import {EvidencePageContext} from "../../../services/EvidencePageService";

type EvidenceCommercialAreaTableDataProps = {
    evidenceId: number,
    currentCommercialArea?: CommercialArea,
    geoId: number,
    groupArea?: Option
}

const CommercialAreaSearch: React.FC<EvidenceCommercialAreaTableDataProps> = ({evidenceId, currentCommercialArea, geoId, groupArea}) => {

    // Default options for search
    const tableContext = useContext(EvidenceTableContext);
    const {updateCommercialAreaOfEvidences} = useContext(EvidencePageContext)
    const defaultOptions = tableContext.availableCommercialAreas.map((cm) => ({id: cm.commercial_area_id, name: cm.name}))

    const [singleSelection, setSingleSelection] = useState([(groupArea) ? groupArea : {
        id: currentCommercialArea?.commercial_area_id,
        name: currentCommercialArea?.name
    }]);
    const [options, setOptions] = useState<{ id: number, name: string }[]>(defaultOptions);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [lastAreaName, setLastAreaName] = useState<string | undefined>(currentCommercialArea?.name);

    const ref = useRef(null);
    const queryName = (ref.current?.state?.text) ? encodeURIComponent(ref.current.state.text) : '';

    const navigate = useNavigate();

    useEffect(() => {
        if (groupArea)
            setSingleSelection([groupArea])
    }, [groupArea, currentCommercialArea])

    const handleOnChange = (input: any) => {
        setShowErrorMessage(false);
        setShowSuccessMessage(false);
        setSingleSelection(input);

        if (input[0] !== undefined)
            API.post('/api/evidence/update', {evidence_id: evidenceId, commercial_area_id: input[0].id}, {withCredentials: true})
                    .then((response: AxiosResponse<{ payload: { success: boolean } }>) => {
                        setShowSuccessMessage(true);
                        setLastAreaName(input[0].name)
                        console.log([Number(evidenceId)], input[0].id);
                        updateCommercialAreaOfEvidences([evidenceId], input[0].id)
                    })
                    .catch()
    }

    const handleInputChange = (input: string) => {
        if (input === '') {
            setOptions(defaultOptions);
            return;
        }

        const newOptions = defaultOptions.filter((o) => o.name.includes(input));
        setOptions(newOptions);
    }

    const handleOnBlur = () => {
        setShowErrorMessage(false);

        if (ref === null || ref.current === null || options.length === 0) {
            // @ts-ignore
            ref.current.setState(singleSelection);
            return;
        }

        // @ts-ignore
        if (ref.current.getInput().value === '') {
            // @ts-ignore
            ref.current.setState(singleSelection);
            return;
        }

        // @ts-ignore
        const foundInOptions = [...singleSelection, ...options].find((option) => option.name === ref?.current?.getInput().value)
        if (foundInOptions === undefined)
            setShowErrorMessage(true);
    }

    // Reset selected option on focus
    const handleOnFocus = () => {
        // @ts-ignore
        ref.current.setState({text: ""});
        setSingleSelection([])
    };

    return (
                <Form.Group>
                    <div className="d-flex">
                        <div style={{width: '90%'}}>
                            <Typeahead
                                    id="basic-typeahead-single"
                                    className={'py-1'}
                                    labelKey="name"
                                    //defaultInputValue={currentCommercialArea?.name}
                                    onChange={handleOnChange}
                                    options={options}
                                    placeholder="Search a commercial area..."
                                    selected={(singleSelection[0]?.id !== undefined) ? singleSelection : [] as Option[]}
                                    onInputChange={handleInputChange}
                                    onBlur={handleOnBlur}
                                    onFocus={handleOnFocus}
                                    ref={ref}
                            />
                        </div>
                        <div id="icon-add" className="px-3">
                            <FontAwesomeIcon icon={faPlusCircle} style={{color: 'green'}}
                                             onClick={() => navigate(`/commercial-area?geo_id=${geoId}&name=${queryName}&evidence_id=${evidenceId}`)}/>
                        </div>
                    </div>
                    {showErrorMessage && <p style={{color: 'red'}}>No valid Commercial Area selected.</p>}
                    {showSuccessMessage && <p style={{color: 'green'}}>Assignment saved.</p>}
                </Form.Group>
    );
}

export default CommercialAreaSearch;
