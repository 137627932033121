import {Key} from "react";

export type SortOrder = 'ASC' | 'DESC';
export type TableHeaderCol = { key: Key, label: string, className?: string }
export type TableHeader = TableHeaderCol[];
export type Data = any[];
export type SortKeys = keyof Data[0];

interface UseSortProps {
    /** Data to sort */
    tableData: Data
    /** Key to sort */
    sortKey: SortKeys;
    /** Flag to reverse data */
    reverse: boolean;
}

function useSort({tableData, sortKey, reverse}: UseSortProps) {

    // no sorting default
    if (!sortKey)
        return tableData;

    // sort data
    const sortedData = tableData.sort((a, b) => {
        const sort_a = (typeof a[sortKey] === 'string') ? a[sortKey].toLowerCase() : a[sortKey];
        const sort_b = (typeof b[sortKey] === 'string') ? b[sortKey].toLowerCase() : b[sortKey];

        return sort_a === sort_b ? 0 : sort_a > sort_b ? 1 : -1;
    })

    if (reverse)
        return sortedData.reverse();

    return sortedData;
}

export default useSort;