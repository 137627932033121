import {useMemo, useState} from "react";

interface ILocalStorageProps {
    key: string,
    initialValue?: string
}

// @ts-ignore
export function useLocalStorage({key, initialValue}: ILocalStorageProps) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: any) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                      value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };

    const unsetItem = (key: any) => {
        try {
            window.localStorage.removeItem(key)
        } catch (error) {
            console.log(error);
        }
    }

    return useMemo(() => {
        return {value: storedValue, setValue: setValue, unsetItem: unsetItem}
    }, [storedValue]);
}