import React, {useEffect, useState} from 'react';
import {generatePath, useNavigate, useParams} from "react-router-dom";
import {AxiosResponse} from "axios";
import {useForm} from "react-hook-form";
import {Button, Card, Col, Form, Row} from "react-bootstrap";

import API from "../../../Request/API";
import PageContainer, {BreadCrumbItem} from "../PageContainer";
import {toast} from "react-toastify";
import {DevPropsDocument, DevPropsSource} from "../../typings";
import {ResultList} from "../../components/Document";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";

const SourceEditPage: React.FC = () => {
    const [currentSource, setCurrentSource] = useState<DevPropsSource>();
    const [currentRegion, setCurrentRegion] = useState<{ id: number, name: string }>({id: -1, name: "Keine Region gefunden.."});
    const [sourceTypes, setSourceTypes] = useState<string[]>();
    const [options, setOptions] = useState<{ id: number, name: string }[]>([]);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [log, setLog] = useState<string>('');
    const [testResults, setTestResults] = useState<DevPropsDocument[]>([])
    const [currentDocuments, setCurrentDocuments] = useState<DevPropsDocument[]>([]);


    const {register, watch, handleSubmit, setValue} = useForm({mode: "onChange"});
    const {source} = watch();
    const id = parseInt(useParams().id as string);
    const navigate = useNavigate();

    const breadCrumbs: BreadCrumbItem[] = [
        {'url': '/', name: 'Home'},
        {'url': '/source', name: 'Sources'},
        {'url': `/source/${id}`, name: id ? String(id) : ''}
    ]

    const Route = (source_id: string | number): string => {
        return generatePath('/source/:id', {'id': source_id as string});
    };

    const handleSourceSubmit = async () => {
        if (!currentRegion)
            return;

        source.geo_id = currentRegion.id;

        try {
            const response: AxiosResponse<{ payload: DevPropsSource }> = await API.post(`/api/source/update`, source, {withCredentials: true})

            if (response.status === 200) {
                toast.success('Source updated!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    async function runCrawler(endpoint: string) {
        try {
            setTestResults([]);
            setCurrentDocuments([])
            setLog('Please wait...');

            const postData = {
                'data': JSON.stringify({
                    'geo_id': currentRegion.id,
                    'type': source.type,
                    'link': source.link,
                    'parameters': source.parameters || {}
                })
            };

            // check if request was handles successfully
            const response = await API.post(endpoint, postData, {withCredentials: true, validateStatus: (_) => true});

            if (response.status !== 200 || response.data?.success !== true || !response.data?.payload) {
                if (typeof (response?.data) === "string")
                    setLog(`Error: ${response.data}`);

                else if (typeof (response?.data) === "object" && response?.data?.error)
                    setLog(`Error: ${response.data.error}`);

                return;
            }

            const {documents, log} = response.data.payload;

            setLog(log);
            setTestResults(documents);

            return response.data.payload;
        } catch (e) {
            setLog(`Error: ${e}`);

            return null;
        }
    }

    const runTestCrawl = () => runCrawler('/api/source/test');
    const runParameterDetection = async () => {
        const response = await runCrawler('/api/source/detect_parameters');

        if (!response?.parameters)
            return;

        source.parameters = JSON.stringify(response.parameters as object);
        setValue('source', source);
    };

    const handleOnChange = (input: { id: number, name: string }) => setCurrentRegion(input)

    const handleInputChange = (input: string) => {
        if (input === '') {
            setOptions([]);
            return;
        }

        API.get(`/api/region?region_name=${input}`, {withCredentials: true})
                .then((response: AxiosResponse<any>) => {
                    const regions = response.data.payload.map((element: any) => ({
                        id: element.geo_id,
                        name: element.name
                    }));
                    setOptions(regions);
                })
                .catch()
                .finally();
    }

    const loadSource = async () => {
        try {
            let response: AxiosResponse;
            response = await API.get(`/api/source/${id}`, {withCredentials: true})
            const {source, documents} = response.data.payload;

            setCurrentSource(source);
            setCurrentDocuments(documents);

            setValue('source', source);


            response = await API.get(`/api/hierarchy?geo_id=${source.geo_id}`, {withCredentials: true})
            const r = response.data.payload[0];
            const newRegion = {id: r.geo_id, name: r.name}

            console.log(r)
            setCurrentRegion(newRegion);
            setOptions([newRegion]);
        } catch (e) {
            console.error(e);
        }
    }

    // load source types
    useEffect(() => {
        API.get(`/api/source/types`, {withCredentials: true})
                .then(({data}) => setSourceTypes(data.payload))
    }, [])


    useEffect(() => {
        setIsReady(false);

        try {
            // reset state
            setCurrentSource(undefined);
            setCurrentRegion({'id': 0, 'name': ''});
            setCurrentDocuments([])
            setLog('');
            setTestResults([]);

            loadSource().then(() => setIsReady(true));
        } catch (e) {
            console.error(e);
        }
    }, [id, setValue]);


    useEffect(() => {
    }, [currentRegion]);

    // check if parameters are valid JSON
    let paramsIsValidJSON;
    try {
        paramsIsValidJSON = !source?.parameters || typeof JSON.parse(source.parameters) === 'object';
    } catch (e) {
        paramsIsValidJSON = false;
    }

    return (
            <PageContainer breadcrumbs={breadCrumbs}>
                <h1>Edit Source</h1>
                {id &&
                        <div>
                            <a onClick={() => navigate(Route(id - 1))} className={'btn btn-default'}>
                                &laquo; {id - 1}
                            </a>

                            <a onClick={() => navigate(Route(id + 1))} className={'btn btn-default float-start float-end'}>
                                {id + 1} &raquo;
                            </a>
                        </div>
                }

                {isReady && currentSource && sourceTypes &&
                        <Card className={'card card-bleed shadow-light-lg mb-3'}>
                            <Card.Header>
                                <strong>{currentSource.source_id} - {currentRegion?.name}</strong>
                            </Card.Header>
                            <Card.Body>
                                <Col>
                                    <Form onSubmit={handleSubmit(handleSourceSubmit)}>
                                        {/*
                                        <Form.Group>
                                            <Form.Label>Geo ID</Form.Label>
                                            <Typeahead
                                                    id="basic-typeahead-single"
                                                    className={'py-2'}
                                                    labelKey="name"
                                                    options={options}
                                                    placeholder="Choose a state..."
                                                    defaultSelected={[currentRegion as { id: number, name: string }]}
                                                    ref={ref}
                                                    disabled={true}
                                            />
                                        </Form.Group>
                                        */}


                                        <Row className="mb-3">
                                            <Col xs={4}>
                                                <Form.Group>
                                                    <Form.Label>Type</Form.Label>
                                                    <Form.Select {...register('source.type')} id={"type"} className={'py-2'} defaultValue={currentSource.type}>
                                                        {sourceTypes.map((type) => <option key={type} value={type}>{type}</option>)}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Link</Form.Label>
                                                    <Row>
                                                        <Col xs={10}>
                                                            <Form.Control {...register('source.link')} type="text" placeholder="Enter URL"/>
                                                        </Col>
                                                        <Col>
                                                            <Button onClick={() => window.open(source.link)}>
                                                                <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Parameters</Form.Label>
                                            <Form.Control {...register('source.parameters')} as="textarea"/>
                                        </Form.Group>

                                        <Button disabled={!paramsIsValidJSON} className="me-4" variant="primary" type="submit">Save changes</Button>
                                        <Button disabled={!paramsIsValidJSON} className="me-4" variant="success" onClick={runTestCrawl}>Test</Button>
                                        <Button disabled={!paramsIsValidJSON} className="me-4" variant="info" onClick={runParameterDetection}>
                                            Detect Parameters</Button>
                                    </Form>
                                </Col>
                            </Card.Body>
                        </Card>
                }

                {log?.length > 0 &&
                        <Card className={'card card-bleed shadow-light-lg mb-3'}>
                            <Card.Header><strong>Log</strong></Card.Header>
                            <Card.Body>
                                <div style={{fontFamily: 'monospace', fontSize: 'smaller', whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{__html: log}}/>
                            </Card.Body>
                        </Card>}

                {testResults?.length > 0 &&
                        <Card className={'card card-bleed shadow-light-lg mb-3'}>
                            <Card.Header><strong>Results</strong> ({testResults.length})</Card.Header>
                            <Card.Body>
                                <ResultList docs={testResults}/>
                            </Card.Body>
                        </Card>}

                <Card className={'card card-bleed shadow-light-lg mb-3'}>
                    <Card.Header><strong>Latest Documents</strong></Card.Header>
                    <Card.Body>
                        <ResultList docs={currentDocuments}/>
                    </Card.Body>
                </Card>

            </PageContainer>
    );
}

export default SourceEditPage;