import React, {useEffect, useState} from 'react';
import {SourceListElement} from "../../services";
import usePagination from "../../hooks/usePagination";
import {OverlayTrigger, Pagination, Stack, Table, Tooltip} from 'react-bootstrap';
import {Link, useLocation} from "react-router-dom";
import {faEdit, faFolderOpen, faSortDown, faSortUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useSort, {SortKeys, SortOrder, TableHeader} from "../../hooks/useSort";
import {useLocalStorage} from "../../hooks/useLocalStorage";

type TableProps = { sources: SourceListElement[] }

const pageSize = 20;

const SourceTable: React.FC<TableProps> = ({sources}) => {

    const storageSourcePage = useLocalStorage({key: 'source-page'});
    const search = useLocation().search;

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [sortKey, setSortKey] = useState<SortKeys>('source_id');
    const [sortOrder, setSortOrder] = useState<SortOrder>('ASC')

    // Sorting data
    const sortedData = useSort({tableData: sources, sortKey, reverse: sortOrder === 'DESC'});

    // Paginate data
    const paginationRange = usePagination({
        count: sortedData.length,
        page: currentPage,
        rowsPerPage: pageSize,
        rowsPerPageOptions: [10, pageSize, 50]
    })
    const currentSources = sortedData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const headers: TableHeader = [
        {key: 'source_id', label: 'ID'},
        {key: 'hierarchy_name', label: 'Gemeinde', className: 'fit'},
        {key: 'type', label: 'Type', className: 'fit text-center'},
        {key: 'link', label: 'Link', className: 'w-25'},
        {key: 'parameters', label: 'Parameter', className: 'fit'},
        {key: 'actions', label: 'Actions', className: 'fit text-center'},
    ];

    function changeSort(key: SortKeys) {
        setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
        setSortKey(key);
    }

    useEffect(() => {
        const query = new URLSearchParams(search);
        const pageNumber = query.get('page') !== null ? Number(query.get('page')) : storageSourcePage.value !== undefined ? Number(storageSourcePage.value) : 1;

        storageSourcePage.setValue(pageNumber);
        setCurrentPage(pageNumber);
    }, []);

    useEffect(() => {
        storageSourcePage.setValue(currentPage);
    }, [currentPage])

    return (
            <>
                <Table striped bordered hover responsive size="sm">
                    <thead>
                    <tr>
                        {headers.map((row) => {
                            return <th key={row.key}
                                       className={(row.className) ?? ''}
                                       onClick={() => changeSort(row.key)}
                            >
                                {row.label} {(row.key !== sortKey) ? '' : (sortOrder === "ASC") ? <FontAwesomeIcon icon={faSortDown}/> :
                                    <FontAwesomeIcon icon={faSortUp}/>}
                            </th>
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {currentSources.map((source) => {
                                return (
                                        <tr key={source.source_id}>
                                            <td className="fit">{source.source_id}</td>
                                            <td>
                                                {source.hierarchy_name}
                                            </td>
                                            <td className="text-center fit">{source.type}</td>
                                            <td className="text-break">
                                                <a href={source.link} target="_blank" rel="noreferrer">{source.link}</a>
                                            </td>
                                            <td>
                                                <p className="fs-sm text-muted mb-0 fit">
                                                    {source.parameters}
                                                </p>
                                            </td>
                                            <td className="text-nowrap text-center fit">

                                                <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id="button-tooltip-2">Edit Source</Tooltip>}
                                                >
                                                    <Link to={`/source/${source.source_id}`}>
                                                        <div className="badge bg-dark-soft me-2">
                                                            <FontAwesomeIcon icon={faEdit}/>
                                                        </div>
                                                    </Link>
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id="button-tooltip-2">Go to Evidences</Tooltip>}
                                                >
                                                    <Link to={`/evidence/${source.geo_id}`}>
                                                        <div className="badge bg-dark-soft me-2">
                                                            <FontAwesomeIcon icon={faFolderOpen}/>
                                                        </div>
                                                    </Link>
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id="button-tooltip-2">Go to Documents</Tooltip>}
                                                >
                                                    <Link to={`/document/${source.source_id}`}>
                                                        <div className="badge bg-dark-soft me-2">
                                                            <FontAwesomeIcon icon={faFolderOpen}/>
                                                        </div>
                                                    </Link>
                                                </OverlayTrigger>

                                            </td>
                                        </tr>
                                )
                            }
                    )}
                    </tbody>
                </Table>


                {paginationRange.showPagination ? (
                                <Stack gap={2}
                                       className="col-md-5 mx-auto d-flex justify-content-center">
                                    <Pagination size={"sm"}>
                                        {paginationRange.showFirst
                                                ? <Pagination.First
                                                        onClick={() => setCurrentPage(1)}/>
                                                : <></>}
                                        {paginationRange.showPrevious
                                                ? <Pagination.Prev
                                                        onClick={() => setCurrentPage(currentPage - 1)}/>
                                                : <></>}
                                        {paginationRange.pages.map(pageNumber =>
                                                <Pagination.Item key={pageNumber}
                                                                 active={currentPage === pageNumber}
                                                                 onClick={() => {
                                                                     setCurrentPage(pageNumber);
                                                                 }}>
                                                    {pageNumber}
                                                </Pagination.Item>
                                        )}

                                        {paginationRange.showNext
                                                ? <Pagination.Next href={'#ceo-table'}
                                                                   data-scroll='{"offset": 0}'
                                                                   onClick={() => setCurrentPage(currentPage + 1)}/>
                                                : <></>}
                                        {paginationRange.showLast
                                                ? <Pagination.Last
                                                        onClick={() => setCurrentPage(paginationRange.pageCount)}/>
                                                : <></>}
                                    </Pagination>
                                </Stack>
                        )
                        : <></>}
            </>
    );
}

export default SourceTable;
