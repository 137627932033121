import {useMemo} from 'react';

interface UsePaginationProps {
    /** Total number of rows */
    count: number;
    /** The current page */
    page: number;
    /** How many rows per page should be visible */
    rowsPerPage: number;
    /** What are the provided options for rowsPerPage */
    rowsPerPageOptions: number[];
}

function usePagination({count, page, rowsPerPage, rowsPerPageOptions}: UsePaginationProps) {

    // total number of pages
    const pageCount = useMemo(() => Math.ceil(count / rowsPerPage), [count, rowsPerPage]);

    // visible page numbers in Pagination
    const pages = useMemo(() => {
        const value = Array.from(new Array(pageCount), (_, k) => k + 1);
        if (page < 3)
            return value.slice(0, 5);
        if (pageCount - page < 3)
            return value.slice(-5);
        return value.slice(page - 3, page + 2);
    }, [page, pageCount]);

    // flag if "<<" should be shown
    const showFirst = useMemo(() => page > 3, [page]);

    // flag if "<" should be shown
    const showPrevious = useMemo(() => page > 1, [page]);

    // flag if ">" should be shown
    const showNext = useMemo(() => pageCount - page > 0, [page, pageCount]);

    // flag if ">>" should be shown
    const showLast = useMemo(() => pageCount - page > 2, [page, pageCount]);

    // flag if there are pages
    const showPages = useMemo(() => pages.length !== 1, [pages.length]);

    // flag if there is a pagination
    const showPagination = useMemo(() => count >= Math.min(...rowsPerPageOptions), [count, rowsPerPageOptions]);

    return {
        pages,
        pageCount,
        showFirst,
        showNext,
        showLast,
        showPages,
        showPagination,
        showPrevious
    };
}

export default usePagination;