import React from 'react';
import {Spinner} from "react-bootstrap";
import {DevPropsDocument} from "../../../typings";

export type DocumentFileProps = {
    isLoadSource: boolean,
    showText: boolean,
    onLoad: () => void,
    url: string,
    currentDocument: DevPropsDocument
}

export const DocumentFile: React.FC<DocumentFileProps> = ({isLoadSource, showText, onLoad, url, currentDocument}) => {

    return (
            <div style={{width: "95%", height: "95%", marginLeft: 'auto', marginRight: 'auto', display: 'block', overflowY: "scroll"}}>
                {['pdf', 'image'].some((type) => type === currentDocument.type) && <>

                    <div hidden={!isLoadSource} style={{display: 'flex', justifyContent: 'center', paddingTop: '4rem'}}>
                        <Spinner hidden={!isLoadSource} animation="grow"/>
                        <Spinner hidden={!isLoadSource} animation="grow"/>
                        <Spinner hidden={!isLoadSource} animation="grow"/>
                    </div>
                    {currentDocument.type === 'pdf' &&
                            <object hidden={showText}
                                    type={'application/pdf'}
                                    onLoad={onLoad}
                                    data={url}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        marginBottom: '1rem',
                                        display: 'block',
                                        paddingTop: '2rem'
                                    }}>
                                <a href={currentDocument.link} target={'_blank'} rel="noreferrer">Show PDF</a>
                            </object>
                    }

                    {(currentDocument.type === 'text' || currentDocument.type === 'image') &&
                            <div hidden={showText}>
                                <img src={url} alt={''}
                                     style={{width: "95%", marginLeft: 'auto', marginRight: 'auto', display: 'block', paddingTop: '2rem'}}
                                     onLoad={onLoad}/>
                                <a href={currentDocument.link} target={'_blank'} rel="noreferrer">Show Source</a>
                            </div>
                    }
                </>
                }
            </div>
    );
}
