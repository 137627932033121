import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeOpenText, faFilePdf, faImage, faSearch} from "@fortawesome/free-solid-svg-icons";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export type BadgeType = 'PDF' | 'TEXT' | 'IMAGE' | string | undefined;

interface TypeFactoryProps {
    type: BadgeType,
}

interface BadgeFactoryProps extends TypeFactoryProps {
    link: string
}

export const TypeFactory: React.FC<TypeFactoryProps> = ({type}) => {

    switch (type) {
        case "IMAGE":
            return (
                    <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="button-tooltip-2">Image</Tooltip>}
                    >
                    <FontAwesomeIcon icon={faImage} className="bg-info-soft mt-1"/>
                    </OverlayTrigger>
            );
        case "PDF":
            return (
                    <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="button-tooltip-2">PDF</Tooltip>}
                    >
                    <div className="badge badge-rounded-circle bg-info-soft mt-1">
                        <FontAwesomeIcon icon={faFilePdf}/>
                    </div>
                    </OverlayTrigger>
            );
        case "TEXT":
            return (
                    <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="button-tooltip-2">Text</Tooltip>}
                    >
                    <div className="badge badge-rounded-circle bg-info-soft mt-1">
                        <FontAwesomeIcon icon={faEnvelopeOpenText}/>
                    </div>
                    </OverlayTrigger>
            );
        default:
            return (<>n/a</>);
    }
}

const BadgeFactory: React.FC<BadgeFactoryProps> = ({type, link}) => {

    switch (type) {
        case "IMAGE":
            return (
                    <div className="badge badge-rounded-circle bg-success-soft me-4">
                        <a href={link} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faImage}/>
                        </a>
                    </div>
            );
        case "PDF":
            return (
                    <div className="badge badge-rounded-circle bg-success-soft me-4">
                        <a href={link} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFilePdf}/>
                        </a>
                    </div>
            );
        case "TEXT":
            return (
                    <div className="badge badge-rounded-circle bg-success-soft me-4">
                        <a href={link} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faEnvelopeOpenText}/>
                        </a>
                    </div>
            );
        default:
            return (
                    <div className="badge badge-rounded-circle bg-info-soft me-4">
                        <a href={link} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faSearch}/>
                        </a>
                    </div>
            );
    }
}

export default BadgeFactory;
