import React, {useContext} from 'react';
import {SourceListContext} from "../../services";
import PageContainer from "../PageContainer";
import SourceTable from "../../components/Source/SourceTable";

const SourceListPage: React.FC = () => {
    const context = useContext(SourceListContext);

    return (
            <PageContainer>
                <h1>Sources</h1>
                <SourceTable sources={context.sources}/>
            </PageContainer>
    );
}

export default SourceListPage;
