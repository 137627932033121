import React, {useContext} from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {Navigate} from "react-router-dom";
import useAuth from "../services/AuthService";
import {ErrorContext} from "../../Request/ErrorHandler";
import ErrorBagFactory from "../../Request/ErrorBagFactory";

export const LoginPage: React.FC = () => {
    const {authentication, login, authErrors} = useAuth();
    const errContext = useContext(ErrorContext);
    const {
        register,
        watch,
        handleSubmit,
        formState: {errors}
    } = useForm({mode: "onChange"});

    if (authentication !== undefined)
        return <Navigate to="/" replace={true}/>

    const {username, password} = watch();

    const handleLogin = (_data: any, e: any) => {
        e.preventDefault();
        login(username, password).then();
    }

    return (
            <section>
                <Container className={'d-flex flex-column'}>
                    <Row className={'align-items-center justify-content-center gx-0 min-vh-80'}>
                        <Col md={5} lg={4} className="py-8 py-md-11">
                            <h1 className="mb-0 fw-bold text-center">Anmelden </h1>
                            <p className="mb-6 text-center text-muted">Simplify your workflow in minutes.</p>

                            {errContext.errorBag.length > 0 &&
                                    errContext.errorBag.map(err => <ErrorBagFactory responseData={err.data}/>)}

                            <Form className="mb-6" onSubmit={handleSubmit(handleLogin)}>
                                <Form.Group>
                                    <Form.Label
                                            className="form-label"
                                            htmlFor="username"
                                    >
                                        Nutzername
                                    </Form.Label>
                                    <Form.Control
                                            {...register('username', {required: true})}
                                            type="text"
                                            className="form-control"
                                            id="username"
                                            placeholder="username"
                                            autoComplete="off"
                                    />
                                    {authErrors?.username && <p className={"error"}>{authErrors.username}</p>}
                                </Form.Group>

                                <Form.Group className="mb-5">
                                    <Form.Label className="form-label" htmlFor="password">
                                        Passwort
                                    </Form.Label>
                                    <Form.Control
                                            {...register('password', {required: true})}
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            placeholder="Enter your password"
                                            autoComplete="off"/>
                                    {authErrors?.password && <p className={"error"}>{authErrors.password}</p>}
                                </Form.Group>

                                <Button variant={'secondary'}
                                        disabled={errors.password || errors.username}
                                        className="w-100"
                                        type="submit">
                                    Anmelden
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </section>
    );
}

export default LoginPage;