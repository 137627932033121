import React, {useCallback, useEffect, useState} from 'react';
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import API from "../../../Request/API";
import {AxiosResponse} from "axios";
import {GeoHierarchy, Hierarchy} from "../../typings";

type SearchProps = {
    innerRef: any,
    selected: Array<Object | string> | { id: number, name: string } | Object | string,
    changeSelected: (selected: Array<Object | string>) => void,
    unsetSelected: () => void,
};

const HierarchySearch: React.FC<SearchProps> = ({innerRef, selected, changeSelected, unsetSelected}) => {

    // Typeahead states
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<{ id: number, name: string }[]>([]);
    const [query, setQuery] = useState('');
    const [backup, setBackup] = useState<Array<Object | string> | { id: number, name: string } | Object | string>(selected);

    function handleAsyncInputChange(q: string) {
        setQuery(q)
    }

    const makeAndHandleRequest = (query: string) => {
        return API.get(`/api/hierarchy?name=${query}&limit=100&tree=true`)
                .then((res: AxiosResponse<{ payload: GeoHierarchy[] }>) => {
                    return res.data.payload.map((h) => ({
                        'geo_id': h.geo_id,
                        'name': h.name,
                        type: h.type,
                        tree: (h.tree?.map((l: Hierarchy) => l.name))?.join(' » ')
                    }));
                });
    }

    const handleSearch = useCallback((q: string) => {
        if (q.length < 1)
            return;

        setIsLoading(true);
        makeAndHandleRequest(q).then((resp) => {
            setIsLoading(false);
            setOptions(resp)
        })
    }, [])

    useEffect(() => {
        if (selected)
            setBackup(selected)
    }, [selected])

    return (
            <AsyncTypeahead id={'async-search'}
                            isLoading={isLoading}
                            labelKey={option => `${option.name}`}
                            onInputChange={handleAsyncInputChange}
                            onChange={changeSelected}
                            onSearch={handleSearch}
                            onFocus={() => {
                                if (innerRef.current !== null)
                                    innerRef.current.clear()
                                unsetSelected();
                            }}
                            onBlur={() => {

                                if (selected === undefined) {
                                    changeSelected(backup)
                                }
                            }}
                            placeholder={'Search for a hierarchy'}
                            options={options}
                            minLength={2}
                            ref={innerRef}
                            selected={
                                selected ?
                                        [{
                                            'id': selected.geo_id,
                                            'name': selected.name,
                                            type: selected.type,
                                            tree: selected.tree
                                        }] : []}
                            useCache={false}
                            renderMenuItemChildren={(option) => (
                                    <div key={option.id}>
                                        <span className="fw-bold">{option.name}</span> <span><small>({option.type})</small></span>
                                        <br/>
                                        <span><small>{option.tree}</small></span>
                                    </div>
                            )}
            />
    );
}

export default HierarchySearch;
