import React, {Component} from 'react';
import {Container} from "react-bootstrap";


class LandingPage extends Component {
    render() {
        return (
            <section className="row-section">
                <Container fluid>
                    <div className="row">
                        <h2 className="text-center">Hello world</h2>
                    </div>
                </Container>
            </section>
        )
    }
}

export default LandingPage;
