// @ts-nocheck
import React, {useEffect, useRef, useState} from 'react';
import {GeoAddress} from "../../typings";
import {Typeahead} from "react-bootstrap-typeahead";

interface AddressSearchProps {
    addresses: GeoAddress[],
    selected: GeoAddress[] | undefined,
    unsetSelected: () => void,
    changeSelected: (selectedAddress: GeoAddress[] | undefined) => void
}

const AddressSearch: React.FC<AddressSearchProps> = ({addresses, selected, unsetSelected, changeSelected,}) => {
    const [options, setOptions] = useState<GeoAddress[]>(addresses);
    const [backup, setBackup] = useState<GeoAddress[] | undefined>(selected);
    const ref = useRef(null);

    useEffect(() => {
        setOptions(addresses);
    }, [addresses]);

    // update backup value if selected has changed and is not undefined
    useEffect(() => {
        if (selected)
            setBackup(selected);
    }, [selected])

    return (
            <Typeahead id={'address-search'}
                       inputProps={{autoComplete: "disabled"}}
                       labelKey={option => `${option.street}, ${option.postcode} ${option.city}`}
                       onChange={changeSelected}
                       onFocus={() => {
                           if (ref.current !== null)
                               ref.current.clear()
                           unsetSelected();
                       }}
                       onBlur={() => {

                           if (selected === undefined) {
                               changeSelected(backup)
                           }
                       }}
                       placeholder={'Search for an address'}
                       options={options}
                       selected={selected}
                       filterBy={['street', 'postcode', 'city']}
                       caseSensitive={false}
                       ref={ref}
                       renderMenuItemChildren={(option: GeoAddress[]) => {
                           return (

                                   <div key={option.address_id}>
                                       <span className="fw-bold">{option.street}</span>
                                       <br/>
                                       <span><small>{option.postcode} {option.city}</small></span>
                                   </div>
                           )
                       }
                       }
            />
    );
}

export default AddressSearch;
