import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Select, {MultiValue, SingleValue} from 'react-select'
import {useForm} from "react-hook-form";
import {Button, Card, Col, FloatingLabel, Form, Row} from "react-bootstrap";
import PageContainer from "../PageContainer";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import API from "../../../Request/API";
import {AxiosResponse} from "axios";
import {HierarchyAxiosResponse} from "../../../Request/ResponseTypes";
import {toast} from "react-toastify";
import {MapContainer, TileLayer, useMapEvents} from 'react-leaflet';
import PositionMarker from "../../components/LeafletMap/PositionMarker";
import {LatLng} from "leaflet";
import {CommercialAreaAxiosResponse} from "./CommercialAreaDetailPage";
import HierarchyBradCrumbs from "../../components/CommercialArea/HierarchyBradCrumbs";
import HierarchySearch from "../../components/CommercialArea/HierarchySearch";
import AddressSearch from "../../components/CommercialArea/AddressSearch";
import {GeoAddress, HierarchyTree} from "../../typings";

type LatLngInput = 'lat' | 'lng';

export const UpdateCommercialAreaPage: React.FC = () => {
    const {id} = useParams();

    const [hierarchyTree, setHierarchyTree] = useState<HierarchyTree>();

    // get query params
    const query_params = new URLSearchParams(useLocation().search);
    const geoId = query_params.get('geo_id') ?? undefined;
    const commercialAreaId = (id) ?? query_params.get('commercial_area_id');
    let evidenceId: string | string[] | null = query_params.get('evidence_id');
    evidenceId = evidenceId === null ? '' : evidenceId.split(',');

    // @ts-ignore
    const name = decodeURIComponent(query_params.get('name'));

    // States
    const [selectedHierarchy, setSelectedHierarchy] = useState<string | Object | (string | Object)[] | { id: number; name: string; }>();
    const [selectedAddress, setSelectedAddress] = useState<GeoAddress[] | undefined>([]);
    const [addresses, setAddresses] = useState<GeoAddress[]>([]);
    const [selectedType, setSelectedType] = useState<SingleValue<{ label: string, value: string }>>({label: 'UNKNOWN', value: 'UNKNOWN'});
    const [selectedDesignation, setSelectedDesignation] = useState<MultiValue<{ label: string, value: string }>>();
    const [states, setStates] = useState<string[]>([]);

    const [markerPosition, setMarkerPosition] = useState<LatLng>();
    const [latLngInput, setLatLngInput] = useState<LatLng>();

    // prepare form
    const {register, watch, handleSubmit, setValue, formState: {errors}} = useForm({mode: 'onChange'});
    const {commercialArea} = watch();
    const memorizedArea = useMemo(() => commercialArea, [commercialArea])

    const ref = useRef(null);
    const mapRef = useRef(undefined);
    const navigate = useNavigate();


    // @ts-ignore
    const EventController = ({setMarkerPosition}) => {
        useMapEvents({
            click(e) {
                setMarkerPosition(e.latlng);
                setLatLngInput(e.latlng);
            }
        });
        return null
    }

    const handeFormSubmit = () => {

        const evidenceData = (evidenceId) ? {evidence_id: evidenceId} : {};
        const geoData = (selectedHierarchy?.geo_id) ? {geo_id: selectedHierarchy?.geo_id} : {};
        const cmId = (id) ? {commercial_area_id: Number(id)} : {};
        let submitData = {
            ...commercialArea, ...cmId, ...geoData, ...evidenceData,
            lat: markerPosition?.lat ?? null,
            lon: markerPosition?.lng ?? null,
            address_id: (selectedAddress && selectedAddress.length > 0) ? selectedAddress[0].address_id : null,
            state: selectedType ? selectedType.value : null,
            designation: selectedDesignation ? selectedDesignation.map((d) => d.value).join(', ') : null
        };

        // reset dirty fields to prevent missmatching types
        for (let k of Object.keys(submitData)) {
            submitData[k] = submitData[k] === '' ? null : submitData[k];
        }

        API.post("/api/commercialArea/update", submitData, {withCredentials: true})
                .then((res) => {
                    if (res.status === 200) {
                        // navigate to edit page
                        const newCommercialAreaId = res.data.payload.id;
                        navigate(`/commercial-area/${newCommercialAreaId}`, {replace: true})

                        // show success toast
                        toast.success('Commercial Area updated!', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                });
    }

    const changeLatLng = (inputType: LatLngInput, value: string) => {
        let newCoords: LatLng = {...latLngInput} as LatLng;

        if (inputType === 'lat')
            newCoords.lat = value;
        else
            newCoords.lng = value;
        setLatLngInput(newCoords);

        if (newCoords.lat && newCoords.lng && newCoords.lat !== 0 && newCoords.lng !== 0 && !isNaN(Number(newCoords.lat)) && !isNaN(Number(newCoords.lng))) {
            setMarkerPosition(newCoords);
            mapRef?.current?.setView(newCoords)
        }
    }

    const changeSelect = (selected: Array<Object | string>) => {
        if (selected && selected[0]) {
            setSelectedHierarchy(selected[0]);
            fetchAndHandleAddresses(selected[0].geo_id).then();
        }
    }

    const unsetSelect = () => {
        setSelectedAddress(undefined);
    }

    const unsetSelectedHierarchy = () => {
        setSelectedHierarchy(undefined);
    }

    const changeSelectedAddress = (selectedAddress: GeoAddress[] | undefined) => {
        if (selectedAddress && selectedAddress[0])
            setSelectedAddress([selectedAddress[0]]);
    }

    const fetchAndHandleAddresses = useCallback(async (geoId: number, addressId?: number) => {
        try {
            const response = await API.get(`/api/address?geo_id=${geoId}`);
            const addresses: GeoAddress[] = response.data.payload;
            setAddresses(addresses);

            if (addressId === undefined)
                return;

            const commercialAreaAddress = addresses.find((a) => a.address_id === addressId);
            if (commercialAreaAddress)
                setSelectedAddress([commercialAreaAddress])
        } catch (e) {
            console.error(e);
        }
    }, [])

    const fetchStates = useCallback(async () => {
        try {
            const res = await API.get('/api/commercialArea/state')
            return res.data.payload.states;
        } catch (err) {
            console.error(err);
        }
    }, []);


    useEffect(() => {
    }, [memorizedArea, selectedHierarchy])

    useEffect(() => {
        const fetchAndHandleHierarchy = async (geoId: number, setMarker: boolean) => {
            if (geoId !== null) {
                try {
                    const response: AxiosResponse<HierarchyAxiosResponse> = await API.get(`/api/hierarchy?geo_id=${geoId}`, {withCredentials: true});
                    const payload = response.data.payload[0];
                    setSelectedHierarchy(payload)

                    if (setMarker && payload.coordinates?.lat !== null && payload.coordinates?.lon !== null) {
                        const latLng = new LatLng(payload.coordinates.lat, payload.coordinates.lon)
                        setLatLngInput(latLng);
                        setMarkerPosition(latLng);
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        }

        const fetchData = async () => {
            try {
                const response: AxiosResponse<CommercialAreaAxiosResponse> = await API.get(`/api/commercialArea/${commercialAreaId}`, {withCredentials: true});
                const payload = response.data.payload;

                setHierarchyTree(payload?.hierarchyTree);
                setValue('commercialArea', payload.commercialArea);

                if (payload?.coordinates.lat) {
                    const latLng = new LatLng(payload?.coordinates.lat, payload?.coordinates.lon);
                    setMarkerPosition(latLng);
                    setLatLngInput(latLng);
                }

                if (payload?.commercialArea.state)
                    setSelectedType({value: payload?.commercialArea.state, label: payload?.commercialArea.state});

                if (payload?.commercialArea.designation) {
                    const designation = payload?.commercialArea.designation.split(', ').map((d) => ({value: d, label: d}));
                    setSelectedDesignation(designation);
                }

                const res = await Promise.all([
                    fetchAndHandleHierarchy(payload?.commercialArea.geo_id, payload?.coordinates.lat === undefined || payload?.coordinates.lat === null),
                    fetchAndHandleAddresses(payload?.commercialArea.geo_id, payload?.commercialArea.address_id),
                    fetchStates()
                ]);

                setStates(res[2]);
            } catch (error) {
                console.error(error)
            }
        }
        if (commercialAreaId)
            (async () => {
                await fetchData();
            })();
        else {
            (async () => {
                const res = await Promise.all([
                    fetchAndHandleHierarchy(Number(geoId), true),
                    fetchAndHandleAddresses(Number(geoId)),
                    fetchStates()
                ])
                setStates(res[2]);
            })();
        }

        // override name with query name
        if (name !== null && name !== 'null')
            setValue('commercialArea.name', name);

    }, [commercialAreaId, name, setValue]);

    return (
            <PageContainer breadcrumbs={[]}>
                <h1>{commercialAreaId ? 'Edit' : 'Add'} Commercial Area</h1>

                {hierarchyTree &&
                        <Row className="mb-4 mb-md-4">
                            <Col sm={12}>
                                <HierarchyBradCrumbs hierarchyTree={hierarchyTree}/>
                            </Col>
                        </Row>
                }


                <Card className={'card card-bleed shadow-light-lg mb-6'}>
                    <Card.Body>
                        <Form onSubmit={handleSubmit(handeFormSubmit)}>
                            <Row>

                                <Col>
                                    <Form.Group as={Col} className="mb-3">
                                        <h5>Hierarchy</h5>

                                        <HierarchySearch innerRef={ref}
                                                         selected={selectedHierarchy}
                                                         changeSelected={changeSelect}
                                                         unsetSelected={unsetSelectedHierarchy}
                                        />

                                    </Form.Group>

                                    <Form.Group as={Col} className="mb-3">
                                        <h5>Address</h5>

                                        <AddressSearch addresses={addresses}
                                                       selected={selectedAddress}
                                                       changeSelected={changeSelectedAddress}
                                                       unsetSelected={unsetSelect}
                                        />


                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control {...register('commercialArea.name')} type="text" placeholder="Enter name"/>
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Alias</Form.Label>
                                        <Form.Control {...register('commercialArea.alias')} type="text" placeholder="Enter Alias (optional)"/>
                                    </Form.Group>

                                    <Row className="mb-3 py-2">
                                        <Form.Group as={Col}>
                                            <FloatingLabel label="Area">
                                                <Form.Control {...register('commercialArea.area')} type="number" placeholder="Area"/>
                                            </FloatingLabel>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <FloatingLabel label="Max Plot Size">
                                                <Form.Control {...register('commercialArea.max_plot_size')} type="number" placeholder="Max Plot Size"/>
                                            </FloatingLabel>
                                        </Form.Group>

                                    </Row>

                                    <Form.Group className="mb-3 pb-2">
                                        <FloatingLabel label="Url">
                                            <Form.Control {...register('commercialArea.url')} type="url" placeholder="url"/>
                                        </FloatingLabel>
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Select placeholder="Designation"
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        border: '1px solid #f1f4f8',
                                                        padding: '.5rem '
                                                    })
                                                }}
                                                isMulti
                                                onChange={(options) => {
                                                    setSelectedDesignation(options)
                                                }}
                                                value={selectedDesignation}
                                                options={[
                                                    {value: 'GI', label: 'GI'},
                                                    {value: 'GE', label: 'GE'},
                                                    {value: 'SO', label: 'SO'},
                                                    {value: 'SG', label: 'SG'},
                                                    {value: 'MG', label: 'MG'}
                                                ]}/>
                                    </Form.Group>

                                    <Form.Group className={'my-3'}>
                                        <Select placeholder="Type"
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        border: '1px solid #f1f4f8',
                                                        padding: '.5rem '
                                                    })
                                                }}
                                                onChange={(options) => {
                                                    setSelectedType(options)
                                                }}
                                                value={selectedType}
                                                options={states.map((type: string) => {
                                                    return {value: type, label: type}
                                                })}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Row className="mb-3 py-6">
                                        <Form.Group as={Col}>
                                            <FloatingLabel label="Latitude">
                                                <Form.Control value={latLngInput?.lat}
                                                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                  changeLatLng('lat', e.target?.value.replace(',', '.'))
                                                              }}
                                                              placeholder={'Latitude'}
                                                              style={{height: '3.5rem'}}
                                                              type="text"/>
                                            </FloatingLabel>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <FloatingLabel label="Longitude">
                                                <Form.Control value={latLngInput?.lng}
                                                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                  changeLatLng('lng', e.target?.value.replace(',', '.'))
                                                              }}
                                                              placeholder={'Longitude'}
                                                              style={{height: '3.5rem'}}
                                                              type="text"/>
                                            </FloatingLabel>
                                        </Form.Group>
                                    </Row>


                                    <Form.Group className={'my-3'}>
                                        <MapContainer center={(markerPosition === undefined) ? new LatLng(51.1642292, 10.4541194) : markerPosition}
                                                      zoom={(markerPosition === undefined) ? 5 : 13}
                                                      style={{height: '45vh', width: '100wh'}}
                                                      ref={mapRef}
                                        >
                                            <TileLayer
                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            />

                                            {markerPosition !== undefined && <PositionMarker markerPosition={markerPosition} initZoomLevel={13}/>}

                                            <EventController setMarkerPosition={setMarkerPosition}/>
                                        </MapContainer>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Button variant="primary" className={'me-2'} type="button" onClick={() => navigate(-1)}>
                                Back
                            </Button>

                            <Button variant="success" type="submit" disabled={Object.keys(errors).length !== 0}>
                                Submit
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </PageContainer>
    );
}