import React, {createContext, useState} from 'react';
import {DevPropsDocument} from "../typings";
import API from "../../Request/API";

interface Document extends DevPropsDocument {
    count_evidences: number,
}

interface ContextType {
    errors: any,
    documents: Document[],
    fetchBySourceId: (id: number) => void;
}

export const DocumentContext = createContext<ContextType>({} as ContextType);

export const DocumentService: React.FC = ({children}) => {
    const [documents, setDocuments] = useState<Document[]>([]);
    const [errors, setErrors] = useState();

    const fetchBySourceId = (id: number) => {
        setErrors(undefined);

        API.get(`/api/document?source_id=${id}`, {withCredentials: true})
                .then(res => setDocuments(res.data.payload))
                .catch(e => {
                    setErrors(e);
                    console.debug('doc_err', e)
                });
    }

    return (
            <DocumentContext.Provider value={{errors, documents, fetchBySourceId}}>{children}</DocumentContext.Provider>
    );
}