import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Button, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderOpen, faGlobe} from "@fortawesome/free-solid-svg-icons";
import PageContainer from "../PageContainer";
import {DevPropsDocument, Evidence} from "../../typings";
import {DocumentContext} from "../../services";
import DocumentModal from "../../components/Evidence/Modal/DocumentModal";
import {TypeFactory} from "../../components/BadgeFactory";
import NoDataFound from "../../components/NoDataFound";


export const DocumentPage: React.FC = () => {
    const {errors, documents, fetchBySourceId} = useContext(DocumentContext);
    const [currentDocument, setCurrentDocument] = useState<DevPropsDocument>({} as DevPropsDocument);
    const [isShown, setIsShown] = useState<boolean>(false);
    const {id} = useParams();

    const convertDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString();
    }

    // Fetch new data if id changes
    useEffect(() => {
        fetchBySourceId(Number(id));
    }, [id]);

    // Rerender
    useEffect(() => {
    }, [documents]);

    if (documents.length === 0)
        return <NoDataFound title={'No documents found.'} navigation={{link: '/source', description: 'Back to sources'}}/>

    return (
            <PageContainer>
                <DocumentModal isShown={isShown}
                               setIsShown={setIsShown}
                               currentEvidence={{} as Evidence}
                               currentDocument={currentDocument}
                />

                <h1>Documents</h1>
                <Table striped bordered hover responsive size="sm">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th className="text-end">Datum</th>
                        <th>Topic</th>
                        <th className="text-center">Title</th>
                        <th className="text-end fit"># Evidences</th>
                        <th className="text-center fit">Type</th>
                        <th className="text-center fit">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {documents.map((document) => {
                                return (
                                        <tr key={document.document_id}>
                                            <td className="fit">{document.document_id}</td>
                                            <td className="fit text-end">{convertDate(document.date.date)}</td>
                                            <td className="fit">{document.text?.substring(0, 100)}...</td>
                                            <td className="fit">{document.title}</td>
                                            <td className="text-end fit">{document.count_evidences}</td>
                                            <td className="text-center fit">
                                                <TypeFactory type={document.type?.toUpperCase()}/>
                                            </td>
                                            <td className="text-center fit">
                                                <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id="button-tooltip-2">Open Document</Tooltip>}
                                                >
                                                    <Button className="badge bg-dark-soft">
                                                        <FontAwesomeIcon icon={faFolderOpen} onClick={() => {
                                                            setCurrentDocument(document);
                                                            setIsShown(true);
                                                        }}/>
                                                    </Button>
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id="button-tooltip-2">Go to Source</Tooltip>}
                                                >
                                                    <Button as={"a"} href={document.link} className="badge bg-dark-soft ms-2">
                                                        <FontAwesomeIcon icon={faGlobe}/>
                                                    </Button>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                )
                            }
                    )}
                    </tbody>
                </Table>
            </PageContainer>
    );
}
