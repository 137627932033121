import React, {useState} from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import useRoutes from "../../../Routes";
import {LoginState} from "./LoginState";
import {useLocalStorage} from "../../hooks/useLocalStorage";

const APP_NAME = 'Dev Props'

export const NavigationBar: React.FC = () => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const storage = useLocalStorage({key: 'source-page'});
    const routes = useRoutes();

    return (
            <Navbar collapseOnSelect
                    variant={"dark"}
                    bg={"dark"}
                    expand={'lg'}
                    expanded={expanded}
            >
                <Container fluid>
                    <Navbar.Brand as={Link}
                                  to={routes.LandingPage.path}
                    >
                        {APP_NAME}
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls={'responsive-navbar-nav'}
                                   onClick={() => setExpanded(!expanded)}/>

                    <Navbar.Collapse id={'responsive-navbar-nav'}
                                     style={{padding: '1rem', backgroundColor: ' #1b2a4e'}}>

                        <Nav className="my-2 my-lg-0" style={{maxHeight: '100px'}} navbarScroll>
                            <Nav.Link as={Link} to={'/commercial-area/1'}>Commercial Area</Nav.Link>
                            <Nav.Link as={Link} to={'/evidence/1'}>Evidence</Nav.Link>
                            <Nav.Link as={Link} to={'/source'} onClick={() => {
                                storage.unsetItem('source-page');
                            }}>Source</Nav.Link>
                        </Nav>

                        <LoginState/>

                        <Navbar.Toggle aria-controls={'responsive-navbar-nav'}
                                       onClick={() => setExpanded(!expanded)}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </Navbar.Toggle>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

    );
}